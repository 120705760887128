import { ref, Ref, getCurrentInstance, watch, onBeforeMount } from 'vue';
import api from '@services/api';

import useLoading from '@/views/components/loading/hooks/useLoading';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';

import segmentModel from '@/models/segment/segment';
interface DataFilterSegment {
  indexFilter: number;
  ruleFilter: { ruleName: string; ruleExclude: boolean; selectView: number; selectSegment: number };
}

interface DataType {
  tableid: number;
  brandid: number;
  databaseid: number;
  type: number;
  type_desc: string;
  table_name: string;
  table_desc: string;
  table_label: string;
  has_joined: boolean;
  column_type_flags: number;
  group_type: number;
  dw_datastore: number;
  dw_datastore_desc: string;
  dw_dist_type: number;
  dw_dist_type_desc: string;
  sourceid: any;
  is_delete: boolean;
  created_dt: string;
  updated_dt: string;
}

interface Props {
  ruleSet: CreateSegmentModal.RuleSet;
  indexFilter: number;
  typeFlags: number;
  filterSegment?: DataFilterSegment;
  miniModal?: boolean;
  modalType?: number;
  modalRuleSetName?: string;
  modalRuleSetIndex?: number;
}

export default function useFilterBySegmentCard(props: Props) {
  const { fetchSegmentTabForModal, fetchSegmentListModel } = segmentModel();
  const { openDefaultErrorModal } = useValidationModal();

  const vm = getCurrentInstance()?.proxy;

  const ruleName: Ref<string> = ref('');
  const ruleType: Ref<string> = ref('');
  const selectView: Ref<number> = ref(null!);
  const listView: Ref<any[]> = ref([]);
  const selectSegment: Ref<number> = ref(null!);
  const listSegment: Ref<any[]> = ref([]);

  const { isLoading } = useLoading();

  const fetchView = () => {
    api
      .apiRequest(fetchSegmentTabForModal)
      .then((response) => {
        const allowColumnTypeFlagsList: any[] = [];
        const columnTypeFlagsList = [...new Set((response.data.payload as DataType[]).map((item: any) => item.column_type_flags))];
        columnTypeFlagsList.forEach((flag) => {
          if ((flag & props.typeFlags) != 0) {
            allowColumnTypeFlagsList.push(flag);
          }
        });

        listView.value = response.data.payload.filter((item: any) => allowColumnTypeFlagsList.includes(item.column_type_flags));
      })
      .catch((err) => {
        openDefaultErrorModal(err);
      });
  };

  const fetchSegmentByView = () => {
    fetchSegmentListModel.payload.ref_type = 2;
    fetchSegmentListModel.payload.reference = props.ruleSet.selectSegment != null ? props.ruleSet.rootView : selectView.value;

    isLoading(true);
    api
      .apiRequest(fetchSegmentListModel)
      .then((response) => {
        listSegment.value = response.data.segment_list.filter((element: any) => element.type == 1 || element.type == 3);
      })
      .catch((err) => {
        openDefaultErrorModal(err);
      })
      .finally(() => {
        isLoading(false);
      });
  };
  const onChange = () => {
    vm?.$emit('on-change', {
      indexFilter: props.indexFilter,
      ruleFilter: {
        ruleName: ruleName.value == '' ? '' : ruleName.value,
        ruleExclude: ruleType.value == null ? null! : ruleType.value == 'exclude' ? true : false,
        selectView: selectView.value == null ? null! : selectView.value,
        selectSegment: selectSegment.value == null ? null! : selectSegment.value,
      },
    });
  };

  const initRuleSet = () => {
    const initialRuleSet = JSON.parse(JSON.stringify(props.ruleSet));
    ruleName.value = initialRuleSet.ruleName;
    if (initialRuleSet.flagExclude == null) {
      ruleType.value = null!;
    } else {
      if (initialRuleSet.flagExclude == true) {
        ruleType.value = 'exclude';
      } else {
        ruleType.value = 'include';
      }
    }
    selectView.value = initialRuleSet.rootView;
    selectSegment.value = initialRuleSet.selectSegment;
  };

  onBeforeMount(() => {
    fetchView();
    if (props.ruleSet.selectSegment != null) {
      initRuleSet();
    } else {
      ruleType.value = 'include';
    }
  });

  watch(
    () => props.ruleSet,
    () => {
      initRuleSet();
    },
  );

  watch(
    () => ruleName.value,
    () => {
      onChange();
    },
  );

  watch(
    () => ruleType.value,
    () => {
      onChange();
    },
  );

  watch(
    () => selectView.value,
    () => {
      if (selectView.value == null) {
        selectSegment.value = null!;
        listSegment.value = [];
      } else {
        fetchSegmentByView();
        onChange();
      }
    },
  );

  watch(
    () => selectSegment.value,
    () => {
      onChange();
    },
  );

  return { ruleName, ruleType, selectView, selectSegment, listView, listSegment };
}
