import { onBeforeMount, ref, watch, reactive, Ref, onMounted } from 'vue';

import router from '@/router';
import api from '@services/api';
import { saveAs } from 'file-saver';

import { useI18n } from 'vue-i18n';
import couponManagementModel from '@models/dataConnect/couponManagement';

import useLoading from '@/views/components/loading/hooks/useLoading';
import useSplitDateTime from '@/utils/hooks/useSplitDateTime';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';

interface SortValue {
  Seq: number;
  Key: string;
  Direction: string;
}

export default function useDetailListCoupon() {
  const { t } = useI18n();
  const { isLoading } = useLoading();

  const loading: Ref<boolean> = ref(false);
  const { openErrorModal } = useValidationModal();
  const {
    detailCouponSet,
    fetchCouponCodelist,
    editCouponSet,
    fetchCouponBatchList,
    fetchStatusList,
    fetchModuleChannelList,
    detailChart,
    exportFileCouponSet,
  } = couponManagementModel();
  const couponCodeList: Ref<Dataconnect.Couponlist.CouponCode[]> = ref([]);
  const { splitDate, splitTime } = useSplitDateTime();

  const editResultRef: Ref<HTMLDivElement> = ref(null!);
  const isOpenEditSideModal: Ref<boolean> = ref(false);
  const isSaveActive: Ref<boolean> = ref(false);

  const optionsChart: Ref<Record<string, any>> = ref({});
  const batchStatus: Ref<Record<string, any>> = ref(null!);
  const filterStatus: Ref<Record<string, any>> = ref(null!);

  const payloadFilter: Ref<Record<string, any>> = ref(null!);
  const typeCouponDesc: Ref<string> = ref('Dynamic');
  const typeCoupon: Ref<string> = ref('1');
  const keyTreeSelect: Ref<number> = ref(0);
  const keyChange: Ref<number> = ref(0);
  const sortingResult: Ref<SortValue[]> = ref([]);

  const payloadModuleChanel: Ref<Record<string, any>[]> = ref([
    // {
    //   ModuleId: 1,
    //   SubCheckAll: false,
    //   Channel: [
    //     {
    //       ChannelId: 1,
    //       ChannelName: 'SMS',
    //     },
    //     {
    //       ChannelId: 2,
    //       ChannelName: 'Email',
    //     },
    //   ],
    // },
  ]);

  const dropDownBatch: Ref<Record<string, any>[]> = ref([
    { label: 'Batch 1', batchid: 1 },
    { label: 'Batch 2', batchid: 2 },
    { label: 'Batch 3', batchid: 2 },
  ]);
  const dropDownStatus: Ref<Record<string, any>[]> = ref([
    { label: 'Delivered ', valueStatus: 2 },
    { label: 'Redeemed', valueStatus: 3 },
    { label: 'Available', valueStatus: 4 },
  ]);
  const dropDownChannel: Ref<Record<string, any>[]> = ref([
    // {
    //   id: 'ACM',
    //   label: 'All Communication and Marketing Automation',
    //   children: [
    //     {
    //       id: 'AC',
    //       label: 'All Communication',
    //       children: [
    //         {
    //           id: 'ACsms',
    //           label: 'SMS',
    //         },
    //       ],
    //     },
    //     {
    //       id: 'AM',
    //       label: 'All Marketing Automation',
    //       children: [
    //         {
    //           id: 'AMsms',
    //           label: 'SMS',
    //         },
    //       ],
    //     },
    //   ],
    // },
  ]);

  const ChannelStatus: Ref<string[]> = ref([]);
  const dropDownPagination = [
    { label: '20', valueStatus: 20 },
    { label: '10', valueStatus: 10 },
    { label: '5', valueStatus: 5 },
  ];
  const paginationStatus: Ref<Record<string, any>> = ref(dropDownPagination[0]);

  const pagination: BaseTable.Pagination = reactive({
    currentPage: 1,
    perPage: 20,
    totalRows: 0,
  });

  const detailCoupon: Dataconnect.Couponlist.DetailHistory = reactive({
    status: false,
    reference: '',
    couponName: '',
    description: '',
    valueReference: 0,
    total: '',
    photoName: '',
    photo: null!,
    credit: 0,
    reserved: 0,
    issue: 0,
    remaining: 0,
  });

  const fields: Ref<BaseTable.TableColumn[]> = ref([
    { key: 'no', label: 'No.' },
    { key: 'code', label: 'Coupon Code' },
    { key: 'linkcouponcode', label: 'Link Coupon Code' },
    { key: 'batchid', label: 'Batch' },
    { key: 'ex_date', label: 'Expiration Date' },
    { key: 'ex_time', label: 'Expiration Time' },
    { key: 'channel', label: 'Channel' },
    { key: 'campaignid', label: 'CampaignId', sortable: true },
    { key: 'cid', label: 'CID' },
    { key: 'redeem_dt', label: 'Redeemed_DT' },
    { key: 'redeem_branchname', label: 'Redeemed_Branch' },
    { key: 'detail', label: 'Detail' },
    { key: 'status', label: 'Status' },
  ]);

  function setChart(
    couponType: number,
    redeemed: any,
    delivered: any,
    available: any,
    max: any,
    percentRedeem: any,
    percentDeliver: any,
    percentAvailable: any,
  ) {
    optionsChart.value = {
      tooltip: {
        trigger: 'item',
        backgroundColor: '#27292C',
        borderColor: 'rgba(0, 0, 0, 0)',
        textStyle: {
          color: 'rgba(255, 255, 255, 1)',
        },
        formatter: function (params: any) {
          // const percentage: number = (params.value / Number(max)) * 100;
          // return '<div style="padding: 0px 10px 0px 10px;">' + percentage.toFixed(1) + '%</div>';
          return '<div style="padding: 0px 10px 0px 10px;">' + params.data.percent + '%</div>';
        },
      },
      legend: {
        bottom: 0,
        left: 'center',
      },
      series: [
        {
          name: 'Tracking Code',
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 0,
            borderColor: '#fff',
            borderWidth: 1,
          },
          label: {
            show: false,
            position: 'center',
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 40,
              fontWeight: 'bold',
              formatter:
                couponType == 2
                  ? '{c} '
                  : function (params: any) {
                      return params.data.valueEmphasis;
                    },
            },
          },
          labelLine: {
            show: false,
          },
          data:
            couponType == 2
              ? [
                  {
                    value: redeemed,
                    percent: percentRedeem,
                    name: 'Redeemed',
                    itemStyle: {
                      color: '#606A6C',
                    },
                  },
                  {
                    value: delivered,
                    percent: percentDeliver,
                    name: 'Delivered',
                    itemStyle: {
                      color: '#004E80',
                    },
                  },
                  {
                    value: available,
                    percent: percentAvailable,
                    name: 'Available',
                    itemStyle: {
                      color: '#67AD5B',
                    },
                  },
                ]
              : [
                  {
                    value: percentRedeem,
                    valueEmphasis: redeemed,
                    percent: percentRedeem,
                    name: 'Redeemed',
                    itemStyle: {
                      color: '#606A6C',
                    },
                  },
                  {
                    value: percentDeliver,
                    valueEmphasis: delivered,
                    percent: percentDeliver,
                    name: 'Delivered',
                    itemStyle: {
                      color: '#004E80',
                    },
                  },
                ],
        },
      ],
    };
  }
  function onExportCSV() {
    couponExportCSV();
  }

  const fetchCouponSetDetail = () => {
    detailCouponSet.payload.coupon_id = router.currentRoute.value.query.couponId;
    api
      .apiRequest(detailCouponSet)
      .then((response) => {
        const data: Record<string, any> = response.data.records[0];
        detailCoupon.couponName = data.name;
        detailCoupon.description = data.detail;
        detailCoupon.photoName = data.image_file_name;
        detailCoupon.photo = data.image_token_link;
        detailCoupon.reference = data.reference;
        detailCoupon.status = data.status == 2 ? true : false;
        detailCoupon.total = data.credit_display || 'null';
        detailCoupon.valueReference = data.value_reference;
        detailCoupon.credit = data.credit;
      })
      .catch((error) => {
        openErrorModal(error.data.error.locale['th-th'].title as string, error.data.error.locale['th-th'].message as string);
      });
  };

  const fetchChartDetail = () => {
    detailChart.payload.CouponSetId = router.currentRoute.value.query.couponId;
    api
      .apiRequest(detailChart)
      .then((response) => {
        const data: Array<Record<string, any>> = response.data.records[0];
        if (data.length >= 0) {
          typeCouponDesc.value = data[0].coupontype_desc;
          typeCoupon.value = data[0].coupontype;
          setChart(
            Number(data[0].coupontype),
            data[0].redeem || 0,
            data[0].issue || 0,
            data[0].remaining || 0,
            data[0].credit || 0,
            data[0].percent_redeem,
            data[0].percent_deliver,
            data[0].percent_avaliable,
          );
        } else {
          typeCouponDesc.value = '-';
          typeCoupon.value = '1';
          setChart(1, 0, 0, 0, 0, 0, 0, 0);
        }
      })
      .catch((error) => {
        openErrorModal(error.data.error.locale['th-th'].title as string, error.data.error.locale['th-th'].message as string);
      });
  };
  const couponExportCSV = () => {
    isLoading(true);
    exportFileCouponSet.payload.CouponId = router.currentRoute.value.query.couponId;
    api
      .apiRequest(exportFileCouponSet)
      .then((response) => {
        const datesTime = new Date();
        saveAs(
          response,
          `CouponCode_Report_${
            detailCoupon.couponName
          }_${datesTime.getFullYear()}${datesTime.getMonth()}${datesTime.getDate()}_${datesTime.getHours()}${datesTime.getMinutes()}${datesTime.getSeconds()}.csv`,
        );
      })
      .catch((err) => {
        openErrorModal(err.data.error.locale['th-th'].title as string, err.data.error.locale['th-th'].message as string);
      })
      .finally(() => {
        isLoading(false);
      });
  };

  const fetchCouponBatch = () => {
    fetchCouponBatchList.payload.CouponSetId = router.currentRoute.value.query.couponId;
    api
      .apiRequest(fetchCouponBatchList)
      .then((response) => {
        const data: Array<Record<string, any>> = response.data;
        dropDownBatch.value = [];
        data.forEach((element) => {
          dropDownBatch.value.push({ label: element.value, batchid: element.key });
        });
      })
      .catch((error) => {
        openErrorModal(error.data.error.locale['th-th'].title as string, error.data.error.locale['th-th'].message as string);
      })
      .finally(() => {});
  };
  const fetchCouponFilter = () => {
    api
      .apiRequest(fetchStatusList)
      .then((response) => {
        const data: Array<Record<string, any>> = response.data;
        dropDownStatus.value = [];
        data.forEach((element) => {
          dropDownStatus.value.push({ label: element.status_name, valueStatus: element.statusid });
        });
      })
      .catch((error) => {
        openErrorModal(error.data.error.locale['th-th'].title as string, error.data.error.locale['th-th'].message as string);
      })
      .finally(() => {});
  };
  const fetchModuleChanel = () => {
    api
      .apiRequest(fetchModuleChannelList)
      .then((response) => {
        const data: Array<Record<string, any>> = response.data;
        dropDownChannel.value = transformData(data);
        // ChannelStatus.value = [dropDownChannel.value[0].id];
        // keyTreeSelect.value += 1;
      })
      .catch((error) => {
        openErrorModal(error.data.error.locale['th-th'].title as string, error.data.error.locale['th-th'].message as string);
      })
      .finally(() => {});
  };

  const transformData = (data: Array<Record<string, any>>) => {
    return data.map((item) => ({
      id: item.id.replace(/\s+/g, ''),
      label: item.label,
      moduleid: null,
      chanelid: null,
      children: item.module.map((moduleItem: any) => ({
        id: `${String(moduleItem.label).replace(/\s+/g, '-')}`,
        label: moduleItem.label,
        moduleid: moduleItem.moduleid,
        chanelid: null,
        children: moduleItem.chanel.map((chanelItem: any) => ({
          id: `${String(chanelItem.label).replace(/\s+/g, '')}`,
          label: chanelItem.label,
          moduleid: moduleItem.moduleid,
          chanelid: chanelItem.chanelid,
        })),
      })),
    }));
  };

  const couponStatusText = (data: any) => {
    switch (data.status) {
      case 10:
        return 'Delivered';
      case 1:
        return 'Available';
      case 20:
        return 'Redeemed';
      default:
        return '' + data.statusdisplay;
    }
  };

  const fetchCouponList = () => {
    loading.value = true;
    fetchCouponCodelist.payload.Module_Chanel = payloadModuleChanel.value;
    fetchCouponCodelist.payload.CouponSetId = router.currentRoute.value.query.couponId;
    fetchCouponCodelist.payload.Filters = payloadFilter.value || [];
    fetchCouponCodelist.payload.Ordering = sortingResult.value || [];
    fetchCouponCodelist.payload.Page = pagination.currentPage;
    fetchCouponCodelist.payload.Limit = pagination.perPage;

    api
      .apiRequest(fetchCouponCodelist)
      .then((response) => {
        couponCodeList.value = response.data.data_list;
        couponCodeList.value.forEach(function (record: any) {
          for (const key in record) {
            if ((record.hasOwnProperty(key) && record[key] == null) || record[key] == '') {
              record[key] = '-';
            }
          }
        });
        pagination.totalRows = response.data.filtered_record;
      })
      .catch((error) => {
        openErrorModal(error.data.error.locale['th-th'].title as string, error.data.error.locale['th-th'].message as string);
      })
      .finally(() => {
        loading.value = false;
      });
  };

  const splitDateTable = (date: string) => {
    const apiDate = new Date(date).toISOString();
    const addHours = new Date(apiDate).getTime() + 0 * 60 * 60 * 1000;
    const result = new Date(addHours).toISOString();
    return splitDate(result);
  };

  const splitTimeTable = (date: string) => {
    const apiDate = new Date(date).toISOString();
    const addHours = new Date(apiDate).getTime() + 0 * 60 * 60 * 1000;
    const result = new Date(addHours).toISOString();
    return splitTime(result);
  };

  const onSort = (sort: { key: string; direction: string }) => {
    const column = fields.value.filter((el) => {
      return el.key == sort.key;
    });
    if (column[0].sortable) {
      sortingResult.value = [
        {
          Seq: 1,
          Key: sort.key,
          Direction: sort.direction,
        },
      ];
      fetchCouponList();
    }
  };

  const onOpenEditSideModal = () => {
    isOpenEditSideModal.value = true;
  };

  const onCloseEditSideModal = () => {
    isOpenEditSideModal.value = false;
  };

  const updateStatus = () => {
    let payloadObj: Coupon.PayloadConfirmEditResult = null!;
    payloadObj = {
      coupon_id: Number(router.currentRoute.value.query.couponId),
      status: detailCoupon.status == true ? 3 : 2,
    };
    onSubmitEditDataConfirm(payloadObj);
  };

  const onGetSubmitEditResult = () => {
    const form = (editResultRef as any).value.confirmFormData();
    let payloadObj: Coupon.PayloadConfirmEditResult = null!;

    if (form.Edit_Image) {
      payloadObj = {
        coupon_id: Number(router.currentRoute.value.query.couponId),
        name: form.Name,
        image_name: form.Image_Name,
        image: form.Image,
        description: form.Description,
        value_reference: form.Value_Reference,
        status: form.Status,
        credit: detailCoupon.credit,
        reserved: detailCoupon.reserved,
        issue: detailCoupon.issue,
        remaining: detailCoupon.remaining,
      };
    } else {
      payloadObj = {
        coupon_id: Number(router.currentRoute.value.query.couponId),
        name: form.Name,
        description: form.Description,
        value_reference: form.Value_Reference,
        status: form.Status,
        credit: detailCoupon.credit,
        reserved: detailCoupon.reserved,
        issue: detailCoupon.issue,
        remaining: detailCoupon.remaining,
      };
    }

    onSubmitEditDataConfirm(payloadObj);
  };

  const onSubmitEditDataConfirm = (payload: Coupon.PayloadConfirmEditResult) => {
    isLoading(true);
    const confirm = editCouponSet;
    confirm.payload = window.GlobalRequest.CalculateFormDataObject(payload);

    api
      .apiRequest(confirm)
      .then((response) => {
        if (response.status == 'success') {
          fetchCouponSetDetail();
          fetchCouponList();
          isOpenEditSideModal.value = false;
        }
      })
      .catch((error) => {
        openErrorModal(error.data.error.locale['th-th'].title as string, error.data.error.locale['th-th'].message as string);
      })
      .finally(() => {
        isLoading(false);
      });
  };

  const onCheckChangeData = (e: any) => {
    isSaveActive.value = e;
  };

  function onActionDashboard(data: any) {
    router.push({
      // path: `/communication/email/dashboard-by-campaign/${data.campaignid}`,
      path: `/${data.campaign_url}`,
      query: {
        ...router.currentRoute.value.query,
      },
    });
  }
  function onSelectPagination(data: any) {
    pagination.currentPage = 1;
    pagination.perPage = data.valueStatus;
    keyChange.value++;
    fetchCouponList();
  }

  function onUpdatedSelect(node: any, instanceId: any) {}

  function getChannelName(channelId: number) {
    switch (channelId) {
      case 1:
        return 'SMS';
      case 2:
        return 'Line';
      case 3:
        return 'Email';
      default:
        return '';
    }
  }

  watch(
    () => pagination.currentPage,
    () => {
      fetchCouponList();
    },
  );

  watch(
    () => [batchStatus.value, filterStatus.value, ChannelStatus.value],
    () => {
      const payloadFilterStatus: any = [];
      payloadFilter.value = null!;
      if (batchStatus.value != null) {
        payloadFilterStatus.push({
          Seq: 1,
          Key: 'Seq',
          Value: batchStatus.value.batchid,
        });
      }
      if (filterStatus.value != null) {
        payloadFilterStatus.push({
          Seq: 1,
          Key: 'status',
          Value: filterStatus.value.valueStatus,
        });
      }
      if (ChannelStatus.value != null) {
        const result: Ref<Record<string, any>[]> = ref([]);
        const foundCategory = dropDownChannel.value.find((item: any) => ChannelStatus.value.some((Status) => Status === item.id));
        if (foundCategory) {
          dropDownChannel.value.forEach((category) => {
            category.children.forEach((child: any) => {
              result.value.push({
                ModuleId: child.moduleid,
                SubCheckAll: true,
                Channel: [],
              });
            });
          });
          // result.value.push({
          //   ModuleId: 0,
          // });
        } else {
          dropDownChannel.value.forEach((category) => {
            const foundCategoryChildren = category.children?.find((item: any) => ChannelStatus.value.some((Status) => Status === item.id));
            if (foundCategoryChildren) {
              result.value.push({
                ModuleId: foundCategoryChildren.moduleid,
                SubCheckAll: true,
                Channel: [],
              });
            } else {
              category.children?.forEach((child: any) => {
                const foundChild = child.children?.filter((item: any) => ChannelStatus.value.some((Status) => Status === item.id));
                if (foundChild && foundChild.length > 0) {
                  const formattedChildChannels = foundChild?.map((channel: any) => ({
                    ChannelId: channel.chanelid,
                    ChannelName: getChannelName(Number(channel.chanelid)),
                  }));
                  result.value.push({
                    ModuleId: child.moduleid,
                    SubCheckAll: false,
                    Channel: formattedChildChannels,
                  });
                }
              });
            }
          });
        }

        if (result.value.length > 0) {
          payloadModuleChanel.value = result.value;
        } else {
          payloadModuleChanel.value = [];
        }
      }
      payloadFilter.value = payloadFilterStatus;
      pagination.currentPage = 1;
      fetchCouponList();
    },
  );

  onMounted(() => {
    fetchCouponSetDetail();
    fetchChartDetail();
    fetchCouponBatch();
    fetchCouponFilter();
    fetchModuleChanel();
    fetchCouponList();
  });

  return {
    fields,
    loading,
    pagination,
    detailCoupon,
    editResultRef,
    isSaveActive,
    isOpenEditSideModal,
    couponCodeList,
    optionsChart,
    dropDownPagination,
    paginationStatus,
    dropDownBatch,
    dropDownStatus,
    batchStatus,
    filterStatus,
    dropDownChannel,
    ChannelStatus,
    keyTreeSelect,
    keyChange,
    typeCouponDesc,
    typeCoupon,
    couponStatusText,
    onSort,
    splitDateTable,
    splitTimeTable,
    onOpenEditSideModal,
    onCloseEditSideModal,
    onGetSubmitEditResult,
    onCheckChangeData,
    updateStatus,
    onActionDashboard,
    onSelectPagination,
    onUpdatedSelect,
    onExportCSV,
  };
}
