import { reactive, ref, Ref, getCurrentInstance, watch, onMounted } from 'vue';
import { DateTime } from 'luxon';

//api
import api from '@/services/api';
import templateModel from '@/models/communication/template';
import mkaModel from '@models/mka/mka';

import useValidationModal from '@/views/components/modal/hooks/useValidationModal';

// constants
import { MKA_NODE_TYPE } from '@/constants/modules/mka/mkaDetail';
import { FIRST_PAGE } from '@/constants/modules/mka/mka';
interface Props {
  isOpen: boolean;
  nodeProps: MKA_Nodes.NodesProps;
  dataInfo: MKA_Nodes.NodeDataInfo.DataInfo;
  loading: boolean;
  summaryNodeInfoList: MKA_Nodes.summaryAllNodeInfo[];
}

export default function useDataInfoModal(props: Props) {
  const vm = getCurrentInstance()?.proxy;
  const { openErrorModal, openWarningModal, openSuccessModal } = useValidationModal();

  const { fetchTemplateDetailModel } = templateModel();
  const { fetchMkaRecipientList, resendNodeCommOutOfCredit } = mkaModel();

  const templatePreview = ref<Communication.Template.Template>();

  const dataInfo = ref<MKA_Nodes.NodeDataInfo.DataInfo | null>(props.dataInfo);
  const dataInfoTable = ref<MKA_Nodes.NodeDataInfo.DataTable>();
  const dataInfoFilter = ref<MKA_Nodes.NodeDataInfo.Request.DataTableFilter>({ Search_Query: '', Status: 0, Page: 1, Limit: 10 });
  const sendDateTime: Ref<Date> = ref(null!);
  //loadings
  const loadingTemplate = ref<boolean>(false);
  const loadingRecipientList = ref<boolean>(false);
  const loadingResume = ref<boolean>(false);

  // const tableStatus = ref<number>(0);
  const columns: Ref<BaseTable.TableColumn[]> = ref([]);
  const data: Ref<Record<string, any>[]> = ref([]);
  const pagination: BaseTable.Pagination = reactive({
    currentPage: 1,
    perPage: 10,
    totalRows: 0,
  });

  const templateList = ref<number[]>([]);
  const isPreviewOpen = ref<boolean>(false);

  //search
  const campaignSearch = ref<string>('');
  const campaignSearchDelayEvent = ref<any>(null);
  const campaignCreateLoad = ref<boolean>(false);

  //Check the node selected by user.
  const nodeSelected = ref<string>();
  const dataNodeSelected: Ref<MKA_Nodes.summaryAllNodeInfo | undefined> = ref();

  async function fetchTemplateDetail(templateId: number): Promise<void> {
    loadingTemplate.value = true;
    fetchTemplateDetailModel.payload.TemplateId = templateId;

    return api
      .apiRequest(fetchTemplateDetailModel)
      .then((response) => {
        const responseData = response.data as Communication.Template.Template;
        templatePreview.value = responseData;
        templatePreview.value.contents = props.nodeProps.payload.contents;

        Promise.resolve();
      })
      .catch((err) => {
        openErrorModal(err as string);
        Promise.reject();
      })
      .finally(() => {
        loadingTemplate.value = false;
      });
  }

  async function fetchMkaRecipientListInfo(): Promise<void> {
    loadingRecipientList.value = true;
    fetchMkaRecipientList.payload.JourneyId = props.dataInfo?.journeyid;
    fetchMkaRecipientList.payload.NodeId = props.dataInfo?.nodeid;
    fetchMkaRecipientList.payload.Filter = dataInfoFilter.value;
    return api
      .apiRequest(fetchMkaRecipientList)
      .then((response) => {
        const responseData: MKA_Nodes.NodeDataInfo.DataTable = response.data;
        dataInfoTable.value = responseData;

        if (dataInfoTable.value) {
          // pagination setup
          pagination.currentPage = dataInfoTable.value.data_info.current_page || 0;
          pagination.perPage = 10;
          pagination.totalRows = dataInfoTable.value.data_info.total_record || 0;

          // table setup
          if (dataInfoTable.value.data_info.data_list[0]) {
            // build table
            columns.value = buildRecipientListColumn(dataInfoTable.value.data_info.data_list[0]);
            data.value = buildRecipientListData(dataInfoTable.value.data_info.data_list);
          } else {
            pagination.currentPage = 0;
            pagination.perPage = 10;
            pagination.totalRows = 0;

            // table setup
            columns.value = [];
            data.value = [];
          }
        }

        Promise.resolve();
      })
      .catch(() => {
        Promise.reject();
      })
      .finally(() => {
        loadingRecipientList.value = false;
      });
  }

  // // TODO: move to utils
  // const resolveInfoFilterValue = (value: string | string[]) => {
  //   if (value instanceof Array) {
  //     return value;
  //   } else {
  //     const date = new Date(value);
  //     if (date instanceof Date) {
  //       return DateTime.fromISO(date.toISOString()).toFormat('dd MMM yyyy');
  //     }
  //   }
  // };

  // // TODO: move to utils
  // const resolveDate = (dateInput: any) => {
  //   if (dateInput) {
  //     const date = new Date(dateInput as Date);
  //     let dateStr = '';

  //     try {
  //       dateStr = `${date.getFullYear()}-${date.toLocaleString('default', {
  //         month: '2-digit',
  //       })}-${date.toLocaleString('default', {
  //         day: '2-digit',
  //       })}`;
  //     } catch {
  //       return '';
  //     }

  //     return dateStr;
  //   }

  //   return '';
  // };

  // // TODO: feature get transaction comm
  // const resolveStatus = (status: any): string => {
  //   if (status && status == COMM_Transaction_Status.Sent) {
  //     return 'Success';
  //   } else {
  //     return 'Fail';
  //   }
  // };

  const buildRecipientListData = (recipientList: any): Record<string, any>[] => {
    return recipientList;
  };

  const buildRecipientListColumn = (recipientList: any): BaseTable.TableColumn[] => {
    const keys = Object.keys(recipientList as object);

    return keys.map((item) => {
      return { key: item, label: item };
    });
  };

  function onClickExportDataInfo() {
    if (props.dataInfo) {
      vm?.$emit('export-data-info', props.dataInfo.nodeid, props.nodeProps.category, props.nodeProps.type);
    } else {
      openErrorModal('เกิดปัญหาขัดข้อง');
    }
  }

  function onChangeRecipientStatus(status: number) {
    dataInfoFilter.value.Status = status;
    dataInfoFilter.value.Page = 1;
    fetchMkaRecipientListInfo();
  }

  function onChangeRecipientFilter(filter: MKA_Nodes.NodeDataInfo.Request.DataTableFilter) {
    dataInfoFilter.value = filter;

    fetchMkaRecipientListInfo();
  }

  function onClickPreview() {
    isPreviewOpen.value = true;
  }

  function onClosePreviewModal() {
    isPreviewOpen.value = false;
  }

  function onClickResumeDataInfo() {
    openWarningModal(
      'ยืนยันการส่งข้อมูล',
      'ต้องการยืนยันการเริ่มแคมเปญต่อหรือไม่',
      () => {
        onResendNodeCommOutOfCredit();
      },
      () => {},
    );
  }
  function onResendNodeCommOutOfCredit() {
    sendDateTime.value = new Date();
    loadingResume.value = true;
    resendNodeCommOutOfCredit.payload.JourneyId = props.dataInfo?.journeyid;
    resendNodeCommOutOfCredit.payload.NodeId = props.dataInfo?.nodeid;
    resendNodeCommOutOfCredit.payload.schedule_mode = 1;
    resendNodeCommOutOfCredit.payload.schedule_dt = sendDateTime.value;
    return api
      .apiRequest(resendNodeCommOutOfCredit)
      .then((response) => {
        Promise.resolve();
      })
      .catch((error) => {
        openErrorModal(error.data.error.locale['th-th'].title as string, error.data.error.locale['th-th'].message as string);
        Promise.reject();
      })
      .finally(() => {
        loadingResume.value = false;
        openSuccessModal('ทำรายการสำเร็จ', '', '', undefined, () => location.reload());
      });
  }

  async function onLoadDataInfo() {
    // templatePreview setup
    if (dataInfo.value && dataInfo.value.contents) {
      if (dataInfo.value.contents.length) {
        templateList.value = dataInfo.value.contents.map((item) => {
          return item.templateid || 0;
        });

        fetchTemplateDetail(templateList.value[0]);
      }
    }
    await fetchMkaRecipientListInfo();
  }

  const onCloseModal = () => {
    vm?.$emit('close-modal');
  };

  //search
  const setCampaignSearch = (value: any) => {
    campaignSearch.value = value;

    if (campaignSearchDelayEvent.value != null) {
      clearTimeout(campaignSearchDelayEvent.value as number);
      campaignSearchDelayEvent.value = null;
    }
    campaignSearchDelayEvent.value = setTimeout(() => {
      dataInfoFilter.value.Search_Query = campaignSearch.value;
      dataInfoFilter.value.Page = FIRST_PAGE;
      fetchMkaRecipientListInfo();
    }, 300);
  };

  function resolveDataInfo() {
    // get data selected by user
    for (const value of Object.values<MKA_Nodes.summaryAllNodeInfo>(props.summaryNodeInfoList)) {
      if (value.node_id == props.nodeProps.id) {
        dataNodeSelected.value = value;
      }
    }

    // get type node selected by user
    if (props.dataInfo.node_name && props.dataInfo.type_desc) {
      nodeSelected.value = MKA_NODE_TYPE.Source;
    } else if (props.dataInfo.type_desc) {
      nodeSelected.value = MKA_NODE_TYPE.FlowControl;
    } else if (props.dataInfo.node_name == null && props.dataInfo.type_desc == null) {
      nodeSelected.value = MKA_NODE_TYPE.Action;
    }
  }

  onMounted(() => {
    resolveDataInfo();
  });

  watch(
    () => pagination.currentPage,
    () => {
      onChangeRecipientFilter({
        Search_Query: dataInfoFilter.value.Search_Query,
        Status: dataInfoFilter.value.Status,
        Page: pagination.currentPage,
        Limit: dataInfoFilter.value.Limit,
      });
    },
  );

  watch(
    [() => props.isOpen],
    async () => {
      if (props.isOpen == true) await onLoadDataInfo();
    },
    { immediate: true },
  );
  return {
    columns,
    data,
    pagination,
    onCloseModal,
    dataInfoFilter,
    onClickExportDataInfo,
    // loadings
    loadingRecipientList,
    loadingTemplate,

    templatePreview,
    templateList,
    onChangeRecipientStatus,
    isPreviewOpen,
    onClickPreview,
    onClosePreviewModal,

    //search
    setCampaignSearch,
    campaignCreateLoad,
    campaignSearch,

    //node selected by user
    nodeSelected,
    dataNodeSelected,

    onClickResumeDataInfo,
    loadingResume,
  };
}
