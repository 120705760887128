import { ref, Ref, reactive, onBeforeMount, watch, getCurrentInstance, nextTick } from 'vue';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';

import segmentModel from '@models/segment/segment';
import apiService from '@/services/api';
import router from '@/router';
import { DateTime } from 'luxon';
import { useI18n } from 'vue-i18n';
import { integer } from '@vee-validate/rules';

interface Props {
  isOpen: boolean;
  dataSource: Segment.Datasource;
  columns: DynamicTable.Column[];
  DataTypeOperationList?: DynamicTable.DataTypeOperationList[];
  AggregatePropertyList?: DynamicTable.AggregatePropertyList[];
  isLoading?: boolean;
  isEdit?: boolean;
  isTemplate?: boolean;
  segment?: Segment.Segment;
  segmentConfig?: Segment.Config;
  campaignActive?: boolean | null;
}
interface FilterBySegmentCard {
  indexFilter: number;
  ruleFilter: { ruleName: string; ruleExclude: boolean; selectView: number; selectSegment: number };
}

export default function useCreateSegmentModal(props: Props) {
  const vm = getCurrentInstance()?.proxy;
  const { t } = useI18n();
  const { openDefaultErrorModal } = useValidationModal();

  const { applyWithConditionModel } = segmentModel();

  const condition: CreateSegmentModal.ApplyWithCondition = reactive({
    ruleSetList: [],
    groupByList: [],
  });
  const isSubmit: Ref<boolean> = ref(false);
  const filterSegmentList: Ref<CreateSegmentModal.FilterBySegment[]> = ref([]);
  const columnList: Ref<CreateSegmentModal.RuleColumn[]> = ref([]);
  const aggregateColumnList: Ref<CreateSegmentModal.RuleColumn[]> = ref([]);
  const groupbyColumnList: Ref<CreateSegmentModal.RuleColumn[]> = ref([]);
  const tableId: Ref<number> = ref(0);
  const key: Ref<number> = ref(0);
  const useGroupBy: Ref<boolean> = ref(false);
  const notiOperator: Ref<boolean> = ref(false);
  const currentRuleSetOperator: Ref<{
    label: string;
    value: number;
  }> = ref(null!);

  const timezone = DateTime.fromISO((localStorage.getItem('userInfo') as any)?.timezone || new Date().toISOString()).zoneName || 'UTC+7';
  const today = DateTime.fromISO(new Date().toISOString(), { zone: timezone });

  const isAdvance: Ref<boolean> = ref(false);
  const countChangeOperator: Ref<number> = ref(0);
  const ruleSetOperator: Ref<{
    label: string;
    value: number;
  }> = ref(null!);

  const ruleAggregateOperator: Ref<{
    label: string;
    value: number;
  }> = ref(null!);

  const logicArea: Ref<HTMLElement> = ref(null!);
  const logicAggArea: Ref<HTMLElement> = ref(null!);
  const setScrollContent: Ref<any> = ref(null!);
  const paramurl: Ref<string> = ref(String(router.currentRoute.value.query.segmentId));

  const setOperatorList = [
    { label: t('segment.components.create_segment_modal.operator.and'), value: 1 },
    { label: t('segment.components.create_segment_modal.operator.or'), value: 2 },
    { label: t('segment.components.create_segment_modal.operator.advance'), value: 3 },
  ];
  const setOperatorAggregateList = [
    { label: t('segment.components.create_segment_modal.operator.and'), value: 1 },
    { label: t('segment.components.create_segment_modal.operator.or'), value: 2 },
  ];
  const miniModal: Ref<boolean> = ref(false);
  const modalType: Ref<number> = ref(null!);
  const modalRuleSetName: Ref<string> = ref(null!);
  const modalRuleSetIndex: Ref<number> = ref(null!);

  const addDays = (date: string, days: number) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  const onFilterBySegment = (val: FilterBySegmentCard) => {
    condition.ruleSetList[val.indexFilter - 1].ruleName = val.ruleFilter.ruleName;
    condition.ruleSetList[val.indexFilter - 1].rootView = val.ruleFilter.selectView;
    condition.ruleSetList[val.indexFilter - 1].selectSegment = val.ruleFilter.selectSegment;
    condition.ruleSetList[val.indexFilter - 1].flagExclude = val.ruleFilter.ruleExclude;
  };

  const initColumns = () => {
    const columns = JSON.parse(JSON.stringify(props.columns)) || [];

    let dataSource = null;
    try {
      dataSource = JSON.parse(JSON.stringify(props.dataSource));
      tableId.value = dataSource.tableid;
      columnList.value = columns.filter((element: DynamicTable.Column) => element.is_filter == true);
      aggregateColumnList.value = columns.filter((element: DynamicTable.Column) => element.is_filter == true);
      groupbyColumnList.value = columns;
    } catch (e) {
      dataSource = null;
    }
  };

  const initRuleSet = () => {
    if (!props.segment) return;
    const operator = setOperatorList.find((operator) => operator.value == props.segment?.set_op);
    ruleSetOperator.value = operator!;
    countChangeOperator.value = 0;
    const ruleSetList: CreateSegmentModal.RuleSet[] = [];
    let prepareGroupBylist: CreateSegmentModal.GroupBy[] = [];
    props.segment.rulesets?.forEach((ruleset) => {
      const ruleListFiltered = ruleset.rule_predicates.filter((item) => item.ruletype == 1).sort((a, b) => a.predicate_seq - b.predicate_seq);
      const aggListFiltered = ruleset.rule_predicates.filter((item) => item.ruletype == 2).sort((a, b) => a.predicate_seq - b.predicate_seq);
      const logicOperatorAggregate = setOperatorList.find((operator) => operator.value == ruleset?.agg_op);
      let TypeOperationList = null;
      let FunctionPropertyList = null;
      ruleAggregateOperator.value = logicOperatorAggregate!;

      const ruleList = ruleListFiltered.map((rule) => {
        key.value++;
        let selectedColumn = null;
        let IndexOperationList: number = null!;

        if (columnList.value && columnList.value.length) {
          selectedColumn = columnList.value.find((column) => column.column_id == rule.l_columnid);
          if (selectedColumn == undefined) {
            selectedColumn = columnList.value.find((column) => column.column_name == rule.l_columnname);
          }
          if (selectedColumn) selectedColumn.isSelected = false;
        }
        if (props.DataTypeOperationList) {
          props.DataTypeOperationList?.forEach((TypeOperation, index) => {
            TypeOperationList = TypeOperation.data_types.find((types) => types == rule.r_value_type);
            if (TypeOperationList) {
              IndexOperationList = index;
            }
          });
        }
        return {
          key: key.value,
          ruleName: rule.rulename,
          ruleType: rule.ruletype,
          column: selectedColumn,
          operatorId: rule.exp_comp_op_id,
          unitId: rule.exp_comp_label_unit_id,
          value1:
            (rule?.r_value_type_desc == 'Date' || rule?.r_value_type_desc == 'DateTime2') &&
            rule.exp_comp_op_id != 51 &&
            rule.exp_comp_op_id != 52 &&
            rule.exp_comp_op_id != 53 &&
            rule.exp_comp_op_id != 54 &&
            rule.exp_comp_op_id != 55 &&
            rule.exp_comp_op_id != 56 &&
            rule.exp_comp_op_id != 57 &&
            rule.exp_comp_op_id != 151 &&
            rule.exp_comp_op_id != 152
              ? new Date(rule.r_value as string)
              : rule.exp_comp_op_id == 55 || rule.exp_comp_op_id == 56
              ? parseInt(rule.r_value as string)
              : rule.exp_comp_op_id == 11 || rule.exp_comp_op_id == 111
              ? setKeyTagStringToArray(rule.r_value as string)
              : rule.r_value,
          value2: rule?.r_value_type_desc == 'Date' || rule?.r_value_type_desc == 'DateTime2' ? new Date(rule.r2_value as string) : rule.r2_value,
          warningColumn: selectedColumn
            ? null
            : {
                l_columnalias: rule.l_columnalias,
                l_columnname: rule.l_columnname,
                r_value_type: rule.r_value_type,
                r_value_type_desc: rule.r_value_type_desc,
                operation_list: props.DataTypeOperationList ? props.DataTypeOperationList[IndexOperationList].operation_list : [],
              },
        };
      });
      const aggList = aggListFiltered.map((rule) => {
        key.value++;
        let selectedColumn = null;
        let IndexOperationList: number = null!;
        let IndexAggregatePropertyList: number = null!;
        if (aggregateColumnList.value && aggregateColumnList.value.length) {
          selectedColumn = aggregateColumnList.value.find((column) => column.column_id == rule.l_columnid);
          if (selectedColumn == undefined) {
            selectedColumn = aggregateColumnList.value.find((column) => column.column_name == rule.l_columnname);
          }
          if (selectedColumn) selectedColumn.isSelected = false;
        }
        if (props.DataTypeOperationList) {
          props.DataTypeOperationList?.forEach((TypeOperation, index) => {
            TypeOperationList = TypeOperation.data_types.find((types) => types == rule.r_value_type);
            if (TypeOperationList) {
              IndexOperationList = index;
            }
          });
        }
        if (props.AggregatePropertyList) {
          props.AggregatePropertyList?.forEach((PropertyList, index) => {
            FunctionPropertyList = PropertyList.data_types.find((types) => types == rule.r_value_type);
            if (FunctionPropertyList) {
              IndexAggregatePropertyList = index;
            }
          });
        }
        console.log(selectedColumn);

        return {
          key: key.value,
          ruleName: rule.rulename,
          ruleType: rule.ruletype,
          column: selectedColumn,
          operatorId: rule.exp_comp_op_id,
          unitId: rule.exp_comp_label_unit_id,
          aggregateFuncId: rule.l_aggregatefuncid,
          value1:
            (selectedColumn?.type_desc == 'Date' || selectedColumn?.type_desc == 'DateTime2') &&
            rule.exp_comp_op_id != 51 &&
            rule.exp_comp_op_id != 52 &&
            rule.exp_comp_op_id != 53 &&
            rule.exp_comp_op_id != 54 &&
            rule.exp_comp_op_id != 55 &&
            rule.exp_comp_op_id != 56 &&
            rule.exp_comp_op_id != 57 &&
            rule.exp_comp_op_id != 151 &&
            rule.exp_comp_op_id != 152
              ? new Date(rule.r_value as string)
              : rule.exp_comp_op_id == 55 || rule.exp_comp_op_id == 56
              ? parseInt(rule.r_value as string)
              : rule.exp_comp_op_id == 11 || rule.exp_comp_op_id == 111
              ? setKeyTagStringToArray(rule.r_value as string)
              : rule.r_value,
          value2: rule?.r_value_type_desc == 'Date' || rule?.r_value_type_desc == 'DateTime2' ? new Date(rule.r2_value as string) : rule.r2_value,
          warningColumn: selectedColumn
            ? null
            : {
                l_columnalias: rule.l_columnalias,
                l_columnname: rule.l_columnname,
                r_value_type: rule.r_value_type,
                r_value_type_desc: rule.r_value_type_desc,
                operation_list: props.DataTypeOperationList ? props.DataTypeOperationList[IndexOperationList].operation_list : [],
                aggregate_property: props.AggregatePropertyList ? props.AggregatePropertyList[IndexAggregatePropertyList]?.aggregate_list : [],
              },
        };
      });
      ruleSetList.push({
        // ruleName: ruleset.name,
        ruleName: '',
        logic: '',
        logicTH: '',
        aggLogicEquation: '',
        aggLogicEquationTH: '',
        logicAggregateOperatorId: ruleset.agg_op,
        logicRuleOperatorId: operator?.value,
        useAggregate: aggListFiltered.length > 0,
        rules: ruleList as CreateSegmentModal.Rule[],
        aggregates: aggList as CreateSegmentModal.Rule[],
        useFilter: ruleset.filter_segmentid != null ? true : false,
        flagExclude: ruleset.filter_segmentid != null ? ruleset.filter_flag_exclude : false,
        selectSegment: ruleset.filter_segmentid != null ? ruleset.filter_segmentid : null!,
        rootView: ruleset.filter_segmentid != null ? ruleset.filter_root_tableid : null!,
      });

      prepareGroupBylist = ruleset.listgroupby.map((gb) => {
        let haveId = false;
        let groupBy = null;

        if (groupbyColumnList.value) {
          groupBy = groupbyColumnList.value.find((groupByColumn) => groupByColumn.column_id == gb.columnid);
          if (groupBy) {
            haveId = true;
          }
        }

        return {
          column_id: haveId ? gb.columnid : null!,
          table_id: gb.tableid,
          column_name: gb.columnname ? gb.columnname : null!,
          column_alias: gb.columnalias ? gb.columnalias : null!,
        };
      });
    });
    condition.ruleSetList = ruleSetList;
    condition.groupByList = prepareGroupBylist;
    useGroupBy.value = condition.groupByList.length > 0;
    nextTick(() => {
      condition.ruleSetList.forEach((ruleSet, ruleSetIndex) => {
        setLogicArea(ruleSetIndex);
        if (ruleSet.rules.length > 0) {
          return ruleSet.rules.forEach((rule, ruleIndex) => setRuleSetLogicArea(ruleSetIndex, ruleIndex, rule.ruleName));
        }
      });
    });
  };

  const setLogicArea = (index: number) => {
    if (logicArea.value && ruleSetOperator.value) {
      if (index < 1) {
        return (logicArea.value.innerHTML = `<div>${index + 1}</div>`);
      } else {
        return (logicArea.value.innerHTML +=
          ruleSetOperator.value.value == 3
            ? `<div>${index + 1}</div>`
            : `<div contenteditable="false">${ruleSetOperator.value.label}</div> <div>${index + 1}</div>`);
      }
    }
    if (index < 1) {
      return (logicArea.value.innerHTML = `<div>${index + 1}</div>`);
    } else {
      return (logicArea.value.innerHTML += `<div>${index + 1}</div>`);
    }
  };

  const setAggregateRuleSetLogicArea = (ruleSetIndex: number, ruleIndex: number, ruleName: string) => {
    const ruleSetCard = (vm?.$refs[`ruleSet-${ruleSetIndex}`] as any)[0];
    const operator = setOperatorList.find((operator) => operator.value == condition.ruleSetList[ruleSetIndex].logicAggregateOperatorId);
    ruleAggregateOperator.value = operator!;
    if (ruleSetCard) {
      const logicAggArea = ruleSetCard.$refs.logicAggArea;
      if (!ruleAggregateOperator.value) {
        if (ruleIndex < 1) {
          return (logicAggArea.innerHTML = `<div>${ruleName}</div>`);
        } else {
          return (logicAggArea.innerHTML += `<div>${ruleName}</div>`);
        }
      }

      if (ruleIndex < 1) {
        return (logicAggArea.innerHTML = `<div>${ruleName}</div>`);
      } else {
        return (logicAggArea.innerHTML +=
          ruleAggregateOperator.value.value == 3
            ? `<div>${ruleName}</div>`
            : `<div contenteditable="false">${ruleAggregateOperator.value.label}</div> <div>${ruleName}</div>`);
      }
    }
  };

  const setRuleSetLogicArea = (ruleSetIndex: number, ruleIndex: number, ruleName: string) => {
    const ruleSetCard = (vm?.$refs[`ruleSet-${ruleSetIndex}`] as any)[0];

    if (ruleSetCard) {
      const logicArea = ruleSetCard.$refs.logicArea;

      if (!ruleSetOperator.value) {
        if (ruleIndex < 1) {
          return (logicArea.innerHTML = `<div>${ruleName}</div>`);
        } else {
          return (logicArea.innerHTML += `<div>${ruleName}</div>`);
        }
      }

      if (ruleIndex < 1) {
        return (logicArea.innerHTML = `<div>${ruleName}</div>`);
      } else {
        return (logicArea.innerHTML +=
          ruleSetOperator.value.value == 3
            ? `<div>${ruleName}</div>`
            : `<div contenteditable="false">${ruleSetOperator.value.label}</div> <div>${ruleName}</div>`);
      }
    }
  };

  const addRuleSet = () => {
    condition.ruleSetList.push({
      ruleName: '',
      logic: '',
      logicTH: '',
      aggLogicEquation: '',
      aggLogicEquationTH: '',
      rules: [],
      aggregates: [],
      useAggregate: false,
      logicAggregateOperatorId: null!,
      logicRuleOperatorId: null!,
      flagExclude: null!,
      selectSegment: null!,
      useFilter: false,
      rootView: null!,
    });
    setLogicArea(condition.ruleSetList.length - 1);
    return addRule(condition.ruleSetList.length - 1), scrollIntoView();
  };

  const addFilterSegment = () => {
    condition.ruleSetList.push({
      ruleName: '',
      logic: '',
      logicTH: '',
      aggLogicEquation: '',
      aggLogicEquationTH: '',
      rules: [],
      aggregates: [],
      useAggregate: false,
      logicAggregateOperatorId: null!,
      logicRuleOperatorId: null!,
      flagExclude: null!,
      selectSegment: null!,
      useFilter: true,
      rootView: null!,
    });
    setLogicArea(condition.ruleSetList.length - 1);
    return scrollIntoView();
  };

  const scrollIntoView = () => {
    setScrollContent.value = document.getElementById('footer-content');
    setTimeout(() => {
      if (setScrollContent.value) {
        return setScrollContent.value.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }, 0);
  };

  const addRule = (ruleSetindex: number) => {
    const ruleName = generateRuleName('upper', condition.ruleSetList[ruleSetindex].rules.length);
    if (ruleName) {
      const rule: CreateSegmentModal.Rule = {
        key: key.value,
        ruleName: ruleName,
        ruleType: 1,
        column: null!,
        operatorId: null!,
        unitId: null!,
        value1: null,
      };
      key.value++;
      condition.ruleSetList[ruleSetindex].rules.push(rule);
      return nextTick(() => {
        setRuleSetLogicArea(ruleSetindex, condition.ruleSetList[ruleSetindex].rules.length - 1, ruleName);
      });
    }
  };

  const addAggregate = (ruleSetindex: number) => {
    const ruleName = generateRuleName('lower', condition.ruleSetList[ruleSetindex].aggregates.length);

    if (ruleName) {
      const aggregateFunction: CreateSegmentModal.Rule = {
        key: key.value,
        ruleName: ruleName,
        ruleType: 2,
        column: null!,
        operatorId: null!,
        unitId: null!,
        value1: null,
        aggregateFuncId: null!,
      };
      key.value++;
      condition.ruleSetList[ruleSetindex].aggregates.push(aggregateFunction);
      return nextTick(() => {
        setAggregateRuleSetLogicArea(ruleSetindex, condition.ruleSetList[ruleSetindex].aggregates.length - 1, ruleName);
      });
    }
  };

  const addGroupBy = () => {
    const groupByFunction: CreateSegmentModal.GroupBy = {
      column_id: null!,
      table_id: tableId.value,
    };

    return condition.groupByList.push(groupByFunction);
  };

  const deleteRule = (ruleSetIndex: number, ruleIndex: number) => {
    const deletedRule = condition.ruleSetList[ruleSetIndex].rules[ruleIndex];

    if (deletedRule.column) {
      deletedRule.column.isSelected = false;
    }

    condition.ruleSetList[ruleSetIndex].rules.splice(ruleIndex, 1);
    return condition.ruleSetList[ruleSetIndex].rules.forEach((rule, index) => {
      rule.ruleName = generateRuleName('upper', index);
      return setRuleSetLogicArea(ruleSetIndex, index, rule.ruleName);
    });
  };

  const deleteAggregate = (ruleSetIndex: number, aggregateFunctionIndex: number) => {
    const deletedRule = condition.ruleSetList[ruleSetIndex].aggregates[aggregateFunctionIndex];

    if (deletedRule.column) {
      deletedRule.column.isSelected = false;
    }

    condition.ruleSetList[ruleSetIndex].aggregates.splice(aggregateFunctionIndex, 1);
    if (condition.ruleSetList[ruleSetIndex].aggregates.length == 0) {
      return setAggregateRuleSetLogicArea(ruleSetIndex, -1, '');
    } else {
      return condition.ruleSetList[ruleSetIndex].aggregates.forEach((aggregateFunction, index) => {
        aggregateFunction.ruleName = generateRuleName('lower', index);
        return setAggregateRuleSetLogicArea(ruleSetIndex, index, aggregateFunction.ruleName);
      });
    }
  };
  const deleteGroupBy = (groupbyIndex: number) => {
    condition.groupByList.splice(groupbyIndex, 1);

    return condition.groupByList;
  };

  const generateRuleName = (mode: 'upper' | 'lower', index: number): string => {
    if (mode == 'upper') {
      const charCode = 65 + index;

      return charCode > 90 ? '' : String.fromCharCode(charCode);
    }
    if (mode == 'lower') {
      const charCode = 97 + index;

      return charCode > 122 ? '' : String.fromCharCode(charCode);
    }

    return '';
  };

  const onToggleAggregate = (event: Event, ruleSetIndex: number) => {
    const status = (<HTMLInputElement>event.target).checked;
    condition.ruleSetList[ruleSetIndex].useAggregate = (<HTMLInputElement>event.target).checked;
    if (!status) {
      if (condition.ruleSetList[ruleSetIndex].rules.length == 0) {
        return setModalTypeToggleAggregate(ruleSetIndex), addRule(ruleSetIndex);
      }
      return setModalTypeToggleAggregate(ruleSetIndex);
    }
    useGroupBy.value = condition.ruleSetList.find((item) => item.useAggregate == true)?.useAggregate ? true : false;
    return onOpenToggleAggregate(event, ruleSetIndex);
  };
  const onOpenToggleAggregate = (event: Event, ruleSetIndex: number) => {
    condition.ruleSetList[ruleSetIndex].useAggregate = (<HTMLInputElement>event.target).checked;
    useGroupBy.value = condition.ruleSetList.find((item) => item.useAggregate == true)?.useAggregate ? true : false;
    if (condition.ruleSetList[ruleSetIndex].useAggregate && useGroupBy.value && condition.groupByList.length > 0) {
      return addAggregate(ruleSetIndex);
    }
    return addAggregate(ruleSetIndex), addGroupBy();
  };
  const onCloseToggleAggregate = (ruleSetIndex: number) => {
    condition.ruleSetList[ruleSetIndex].useAggregate = false;
    useGroupBy.value = condition.ruleSetList.find((item) => item.useAggregate == true)?.useAggregate ? true : false;
    if (!condition.ruleSetList[ruleSetIndex].useAggregate) {
      condition.ruleSetList[ruleSetIndex].aggregates = [];
      if (!useGroupBy.value) {
        condition.groupByList = [];
      }
      return condition.ruleSetList[ruleSetIndex].aggregates;
    }
  };
  const setModalTypeToggleAggregate = (ruleSetIndex: number) => {
    const last = condition.ruleSetList.filter((item) => item.useAggregate == true).length;

    modalRuleSetName.value =
      condition.ruleSetList[ruleSetIndex].ruleName.length == 0
        ? '" ' + String(ruleSetIndex + 1) + ' "'
        : '"' + condition.ruleSetList[ruleSetIndex].ruleName + '"';
    if (last >= 1) {
      //เหลือ aggregate มากกว่า 1 rule set
      modalRuleSetIndex.value = ruleSetIndex;
      modalType.value = 2;
      miniModal.value = true;
    } else {
      modalRuleSetIndex.value = ruleSetIndex;
      modalType.value = 1;
      miniModal.value = true;
    }
  };

  const onSetDeleteRuleSetModal = (ruleSetIndex: number) => {
    modalRuleSetIndex.value = ruleSetIndex;
    modalRuleSetName.value =
      condition.ruleSetList[ruleSetIndex].ruleName.length == 0
        ? '" ' + String(ruleSetIndex + 1) + ' "'
        : '"' + condition.ruleSetList[ruleSetIndex].ruleName + '"';

    const countAggregate = condition.ruleSetList.filter((item, index) => item.useAggregate == true && index != ruleSetIndex).length;
    modalType.value = countAggregate >= 1 && useGroupBy.value == true ? 3 : countAggregate == 0 && useGroupBy.value == false ? 3 : 4;
    miniModal.value = true;
  };

  const onSetCancelMainModal = () => {
    if (props.campaignActive) {
      onCloseModal();
    } else {
      if (condition.ruleSetList.length != 0) {
        modalType.value = 5;
        miniModal.value = true;
      } else {
        onCloseModal();
      }
    }
  };

  const onCloseMiniModal = (ruleSetIndex: number, modalsType: number) => {
    miniModal.value = false;

    if (modalsType == 1 || modalsType == 2) {
      condition.ruleSetList[ruleSetIndex].useAggregate = true;
      useGroupBy.value = condition.ruleSetList.find((item) => item.useAggregate == true)?.useAggregate ? true : false;
      nextTick(() => {
        condition.ruleSetList.forEach((ruleSet, ruleSetIndex) => {
          setLogicArea(ruleSetIndex);
          return ruleSet.aggregates.forEach((rule, ruleIndex) => setAggregateRuleSetLogicArea(ruleSetIndex, ruleIndex, rule.ruleName));
        });
      });
    }
    if (modalsType == 3 || modalsType == 4) {
      useGroupBy.value = condition.ruleSetList.find((item) => item.useAggregate == true)?.useAggregate ? true : false;
      return miniModal;
    }
    return miniModal;
  };

  const onConfirmMiniModal = (ruleSetIndex: number, modalType: number) => {
    if (modalType == 1 || modalType == 2) {
      miniModal.value = false;
      return onCloseToggleAggregate(ruleSetIndex);
    }
    if (modalType == 3 || modalType == 4) {
      miniModal.value = false;
      return deleteRuleSet(ruleSetIndex);
    }
  };

  const setFormatDate = (value: Date, column: CreateSegmentModal.RuleColumn) => {
    const event: Ref<string> = ref('');
    const result: Ref<string> = ref('');
    switch (column.column_property.date_edittype) {
      case 1:
        if (column.column_property.covert_utc_flag) {
          const Ms = value.toISOString().split('.')[0] + '.000Z'; //format : ISO
          return Ms;
        }
        event.value = value.toISOString(); //format : ISO
        result.value = DateTime.fromISO(event.value).setLocale('th').toISO().split('.')[0] + '.000Z'; //todo: set zonename on setLocale
        return result.value;
      case 2:
        if (column.column_property.covert_utc_flag) {
          const Ms = value.toISOString().split('.')[0] + '.000Z'; //format : ISO
          return Ms;
        }
        event.value = value.toISOString(); //format : ISO
        result.value = DateTime.fromISO(event.value).setLocale('th').toISO().split('.')[0] + '.000Z'; //todo: set zonename on setLocale
        return result.value;

      case 3:
        if (column.column_property.covert_utc_flag) {
          const Ms = value.toISOString().split('.')[0] + '.000Z'; //format : ISO
          return Ms;
        }
        event.value = value.toISOString(); //format : ISO
        result.value = DateTime.fromISO(event.value).setLocale('th').toISO().split('.')[0] + '.000Z'; //todo: set zonename on setLocale
        return result.value;
    }
  };

  const setAggregateOperator = (operator: number, ruleSetSeq: number) => {
    const obj = setOperatorList.find((item) => item.value == operator);
    condition.ruleSetList[ruleSetSeq].logicAggregateOperatorId = operator;
    ruleAggregateOperator.value = obj!;
    return condition.ruleSetList[ruleSetSeq].aggregates.forEach((aggregateFunction, index) => {
      aggregateFunction.ruleName = generateRuleName('lower', index);
      return setAggregateRuleSetLogicArea(ruleSetSeq, index, aggregateFunction.ruleName);
    });
  };

  const deleteRuleSet = (index: number) => {
    condition.ruleSetList.splice(index, 1);
    useGroupBy.value = condition.ruleSetList.find((item) => item.useAggregate == true)?.useAggregate ? true : false;
    if (!useGroupBy.value) {
      condition.groupByList = [];
    }
    if (condition.ruleSetList && condition.ruleSetList.length) {
      nextTick(() => {
        condition.ruleSetList.forEach((ruleSet, ruleSetIndex) => {
          setLogicArea(ruleSetIndex);
          return (
            ruleSet.aggregates.forEach((rule, ruleIndex) => setAggregateRuleSetLogicArea(ruleSetIndex, ruleIndex, rule.ruleName)),
            ruleSet.rules.forEach((rule, ruleIndex) => setRuleSetLogicArea(ruleSetIndex, ruleIndex, rule.ruleName))
          );
        });
      });
    } else {
      condition.ruleSetList = [];
      condition.groupByList = [];
      filterSegmentList.value = [];
      ruleAggregateOperator.value = null!;
      useGroupBy.value = false;
      key.value = 0;
      miniModal.value = false;
      modalType.value = null!;
      modalRuleSetIndex.value = null!;
      modalRuleSetName.value = null!;
      if (logicArea.value) {
        logicArea.value.innerHTML = '';
        logicArea.value = null!;
      }
      if (logicAggArea.value) {
        logicAggArea.value.innerHTML = '';
        logicAggArea.value = null!;
      }
    }
  };

  const deleteFilterSegment = (index: number) => {
    filterSegmentList.value.splice(index, 1);
  };

  const onSelectRuleColumn = (column: CreateSegmentModal.RuleColumn, ruleIndex: number, ruleSetSeq: number) => {
    // condition.ruleSetList[ruleSetSeq - 1].rules[ruleIndex].operatorId = null!;
    // condition.ruleSetList[ruleSetSeq - 1].rules[ruleIndex].unitId = null!;
    if (column) {
      column.isSelected = false;

      if (condition.ruleSetList[ruleSetSeq - 1].rules[ruleIndex].column) {
        condition.ruleSetList[ruleSetSeq - 1].rules[ruleIndex].column.isSelected = false;
      }
      return (condition.ruleSetList[ruleSetSeq - 1].rules[ruleIndex].column = column);
    }

    const previousSelectedColumn = condition.ruleSetList[ruleSetSeq - 1].rules[ruleIndex].column;

    previousSelectedColumn.isSelected = false;
    condition.ruleSetList[ruleSetSeq - 1].rules[ruleIndex].column = null!;
    condition.ruleSetList[ruleSetSeq - 1].rules[ruleIndex].operatorId = null!;
  };

  const onSelectRuleAggregateColumn = (column: CreateSegmentModal.RuleColumn, ruleIndex: number, ruleSetSeq: number) => {
    // condition.ruleSetList[ruleSetSeq - 1].aggregates[ruleIndex].aggregateFuncId = null!;
    // condition.ruleSetList[ruleSetSeq - 1].aggregates[ruleIndex].operatorId = null!;
    if (column) {
      column.isSelected = false;

      if (condition.ruleSetList[ruleSetSeq - 1].aggregates[ruleIndex].column) {
        condition.ruleSetList[ruleSetSeq - 1].aggregates[ruleIndex].column.isSelected = false;
      }
      return (condition.ruleSetList[ruleSetSeq - 1].aggregates[ruleIndex].column = column);
    }
    const previousSelectedColumn = condition.ruleSetList[ruleSetSeq - 1].aggregates[ruleIndex].column;

    previousSelectedColumn.isSelected = false;
    condition.ruleSetList[ruleSetSeq - 1].aggregates[ruleIndex].column = null!;
    condition.ruleSetList[ruleSetSeq - 1].aggregates[ruleIndex].operatorId = null!;
  };

  const onSelectRuleGroupByFunction = (column: number, ruleIndex: number) => {
    if (column) {
      return (condition.groupByList[ruleIndex].column_id = column);
    }
  };

  const onSelectRuleColumnOperator = (operator: number, ruleIndex: number, ruleSetSeq: number) => {
    // condition.ruleSetList[ruleSetSeq - 1].rules[ruleIndex].value1 = null;
    // condition.ruleSetList[ruleSetSeq - 1].rules[ruleIndex].value2 = null;
    // condition.ruleSetList[ruleSetSeq - 1].rules[ruleIndex].unitId = null!;

    if (operator) {
      if (
        (condition.ruleSetList[ruleSetSeq - 1].rules[ruleIndex].column == null
          ? condition.ruleSetList[ruleSetSeq - 1].rules[ruleIndex].warningColumn.r_value_type_desc == 'Date'
          : condition.ruleSetList[ruleSetSeq - 1].rules[ruleIndex].column.type_desc == 'Date') &&
        operator != 51 &&
        operator != 52 &&
        operator != 53 &&
        operator != 54 &&
        operator != 55 &&
        operator != 56 &&
        operator != 57 &&
        operator != 151
      ) {
        condition.ruleSetList[ruleSetSeq - 1].rules[ruleIndex].value1 = new Date(today.toString());
        condition.ruleSetList[ruleSetSeq - 1].rules[ruleIndex].value2 = addDays(today.toString(), 1);
      }
      return (condition.ruleSetList[ruleSetSeq - 1].rules[ruleIndex].operatorId = operator);
    }
    return (condition.ruleSetList[ruleSetSeq - 1].rules[ruleIndex].operatorId = null!);
  };

  const onSelectRuleAggregateColumnOperator = (operator: number, ruleIndex: number, ruleSetSeq: number) => {
    // condition.ruleSetList[ruleSetSeq - 1].aggregates[ruleIndex].value1 = null;
    // condition.ruleSetList[ruleSetSeq - 1].aggregates[ruleIndex].value2 = null;
    if (operator) {
      if (
        condition.ruleSetList[ruleSetSeq - 1].aggregates[ruleIndex].column.type_desc == 'Date' &&
        operator != 51 &&
        operator != 52 &&
        operator != 53 &&
        operator != 151
      ) {
        condition.ruleSetList[ruleSetSeq - 1].aggregates[ruleIndex].value1 = new Date(today.toString());
        condition.ruleSetList[ruleSetSeq - 1].aggregates[ruleIndex].value2 = addDays(today.toString(), 1);
      }
      return (condition.ruleSetList[ruleSetSeq - 1].aggregates[ruleIndex].operatorId = operator);
    }
    return (condition.ruleSetList[ruleSetSeq - 1].aggregates[ruleIndex].operatorId = null!);
  };

  const onSelectRuleAggregateColumnFunction = (functions: number, ruleIndex: number, ruleSetSeq: number) => {
    // condition.ruleSetList[ruleSetSeq - 1].aggregates[ruleIndex].operatorId = null!;
    // condition.ruleSetList[ruleSetSeq - 1].aggregates[ruleIndex].value1 = null;
    // condition.ruleSetList[ruleSetSeq - 1].aggregates[ruleIndex].value2 = null;
    if (functions) {
      return (condition.ruleSetList[ruleSetSeq - 1].aggregates[ruleIndex].aggregateFuncId = functions);
    }
    return (condition.ruleSetList[ruleSetSeq - 1].aggregates[ruleIndex].aggregateFuncId = null!);
  };

  const onSelectRuleColumnUnit = (unit: number, ruleIndex: number, ruleSetSeq: number) => {
    // condition.ruleSetList[ruleSetSeq - 1].rules[ruleIndex].value1 = null;
    // condition.ruleSetList[ruleSetSeq - 1].rules[ruleIndex].value2 = null;

    if (unit) {
      return (condition.ruleSetList[ruleSetSeq - 1].rules[ruleIndex].unitId = unit);
    }

    return (condition.ruleSetList[ruleSetSeq - 1].rules[ruleIndex].unitId = null!);
  };

  const onSetRuleName = (value: string, seq: number) => {
    const ruleSetIndex = seq - 1;
    const rule = condition.ruleSetList[ruleSetIndex];

    if (rule) rule.ruleName = value;
  };

  const onInputColumnValue = (event: Event, type: number, ruleIndex: number, seq: number) => {
    const ruleSetIndex = seq - 1;
    const value = (<HTMLInputElement>event.target).value || '';
    const rule = condition.ruleSetList[ruleSetIndex].rules[ruleIndex];
    const typeTag: Ref<number> = ref(type);
    if (typeTag.value == 1) {
      return (rule.value1 = value);
    }
    if (typeTag.value == 2) {
      return (rule.value2 = value);
    }
  };

  const onKeyRuleTag = (event: any, type: number, ruleIndex: number, seq: number) => {
    const ruleSetIndex = seq - 1;
    const rule = condition.ruleSetList[ruleSetIndex].rules[ruleIndex];
    const typeTag: Ref<number> = ref(type);
    if (typeTag.value == 1) {
      return (rule.value1 = event);
    }
    if (typeTag.value == 2) {
      return (rule.value2 = event);
    }
  };

  const onInputColumnValueDataList = (event: Event, type: number, ruleIndex: number, seq: number) => {
    const ruleSetIndex = seq - 1;
    const rule = condition.ruleSetList[ruleSetIndex].rules[ruleIndex];
    const typeTag: Ref<number> = ref(type);
    if (typeTag.value == 1) {
      return (rule.value1 = event);
    }
    if (typeTag.value == 2) {
      return (rule.value2 = event);
    }
  };
  const onInputAggregateColumnValue = (event: Event, type: number, ruleIndex: number, seq: number) => {
    const ruleSetIndex = seq - 1;
    const value = (<HTMLInputElement>event.target).value || '';
    const aggregate = condition.ruleSetList[ruleSetIndex].aggregates[ruleIndex];

    if (type == 1) {
      return (aggregate.value1 = value);
    }
    if (type == 2) {
      return (aggregate.value2 = value);
    }
  };

  const getRuleSetLogicalEquation = (): string => {
    const logicSection = logicArea.value;

    if (logicSection) {
      return logicSection.innerText.replaceAll('\n', ' ');
    }

    return '';
  };

  const getRuleLogicalEquation = (ruleSetIndex: number): string => {
    const ruleSetCard = (vm?.$refs[`ruleSet-${ruleSetIndex}`] as any)[0];

    if (ruleSetCard) {
      const logicSection = ruleSetCard.$refs.logicArea as HTMLElement;

      return logicSection?.innerText.replaceAll('\n', ' ') || ' ';
    }

    return '';
  };

  const getRuleAggregateLogicalEquation = (ruleSetIndex: number): string => {
    const ruleSetCard = (vm?.$refs[`ruleSet-${ruleSetIndex}`] as any)[0];

    if (ruleSetCard) {
      const logicSection = ruleSetCard.$refs.logicAggArea as HTMLElement;

      return logicSection?.innerText.replaceAll('\n', ' ') || ' ';
    }

    return '';
  };
  const setMainOperator = (value: any) => {
    if (ruleSetOperator.value == null) {
      ruleSetOperator.value = value;
    } else if (ruleSetOperator.value != null) {
      if (value.value == 3 && ruleSetOperator.value.value != 3) {
        currentRuleSetOperator.value = value;
        notiOperator.value = true;
      } else if (value.value != 3 && ruleSetOperator.value.value == 3) {
        currentRuleSetOperator.value = value;
        notiOperator.value = true;
      } else {
        ruleSetOperator.value = value;
        if (condition.ruleSetList && condition.ruleSetList.length) {
          isAdvance.value = false;
          nextTick(() => {
            condition.ruleSetList.forEach((ruleSet, ruleSetIndex) => {
              setLogicArea(ruleSetIndex);
              if (ruleSet.rules.length > 0) {
                return ruleSet.rules.forEach((rule, ruleIndex) => setRuleSetLogicArea(ruleSetIndex, ruleIndex, rule.ruleName));
              }
            });
          });
        }
      }
    }
  };
  const confirmSetMainOperator = (value: any) => {
    if (value.value == 3) {
      condition.ruleSetList = [];
      condition.groupByList = [];
      filterSegmentList.value = [];
      ruleAggregateOperator.value = null!;
      useGroupBy.value = false;
      key.value = 0;
      miniModal.value = false;
      modalType.value = null!;
      modalRuleSetIndex.value = null!;
      modalRuleSetName.value = null!;
      if (logicArea.value) {
        logicArea.value.innerHTML = '';
        logicArea.value = null!;
      }
      if (logicAggArea.value) {
        logicAggArea.value.innerHTML = '';
        logicAggArea.value = null!;
      }
      isAdvance.value = true;
      ruleSetOperator.value = value;
    } else {
      isAdvance.value = false;
      ruleSetOperator.value = value;
    }
    notiOperator.value = false;
  };

  const unSetMainOperator = () => {
    notiOperator.value = false;
  };

  const setConfigFlagSegment = () => {
    const filterConfig = props.segmentConfig?.filter_by_segment;
    const rulesetConfig = props.segmentConfig?.multiple_ruleset;

    if (filterConfig && rulesetConfig) {
      return false;
    } else if (filterConfig && !rulesetConfig) {
      if (condition.ruleSetList.length > 0) {
        return true;
      }
      return false;
    } else if (!filterConfig && rulesetConfig) {
      return true;
    }
    return true;
  };

  const setKeyTagStringToArray = (stringValue: string): string[] => {
    let result = [];
    result = stringValue.split(',');
    return result;
  };

  const setKeyTagArrayToString = (arrayValue: any[]): string => {
    let result = '';
    arrayValue.forEach((element, index) => {
      if (index == arrayValue.length - 1) {
        result += arrayValue[index];
      } else {
        result += arrayValue[index] + ',';
      }
    });
    return result;
  };

  const createPayload = () => {
    applyWithConditionModel.payload.root_TableId = props.dataSource.tableid;
    applyWithConditionModel.payload.name = '';
    applyWithConditionModel.payload.set_Op = ruleSetOperator.value.value as Segment.SegmentOperation;
    applyWithConditionModel.payload.set_Logical_Equation = getRuleSetLogicalEquation();

    if (props.isEdit) {
      applyWithConditionModel.payload.Apply_SegmentId =
        Number(router.currentRoute.value.query.applySegmentId) || Number(router.currentRoute.value.query.segmentId);
    } else {
      applyWithConditionModel.payload.Apply_SegmentId = Number(router.currentRoute.value.query.applySegmentId) || null;
    }

    const gatherRuleAndAggregateList: any[] = [];
    condition.ruleSetList.forEach((ruleSet, ruleSetIndex) => {
      const prepareRule: Segment.Request.Rule[] = [];
      const prepareAggregate: Segment.Request.Rule[] = [];
      ruleSet.rules.map((rule, index) => {
        const resolvedRule: Segment.Request.Rule = {
          seq: index + 1,
          ruleName: rule.ruleName,
          ruleType: rule.ruleType,
          agg_Column_Alias: null,
          Exp_Comp_Op_Id: rule.operatorId as Segment.EXPCompOpId,
          exp_Comp_Label: null,
          exp_Comp_Label_Unit_Id: rule.unitId,
          l_TableId: applyWithConditionModel.payload.root_TableId,
          l_ColumnId: rule.column?.column_id,
          l_AggregateFuncId: null,
          r_Value_Type: rule.column?.data_type,
          r_Value_Label: null,
          r_Value:
            (rule.column ? rule.column.type_desc == 'Date' : rule.warningColumn.r_value_type_desc == 'Date') &&
            rule.operatorId != 51 &&
            rule.operatorId != 52 &&
            rule.operatorId != 53 &&
            rule.operatorId != 54 &&
            rule.operatorId != 55 &&
            rule.operatorId != 56 &&
            rule.operatorId != 57 &&
            rule.operatorId != 58 &&
            rule.operatorId != 151
              ? setFormatDate(<Date>rule.value1, rule.column)
              : rule.operatorId == 11 || rule.operatorId == 111
              ? setKeyTagArrayToString(rule.value1 as any[])
              : rule.operatorId == 58
              ? ''
              : rule.value1 == null
              ? ''
              : rule.value1,
          r2_Value_Type: null,
          r2_Value_Label: null,
          r2_Value: null,
        };
        if (rule.operatorId == 9 || rule.operatorId == 109) {
          resolvedRule.r2_Value_Type = rule.column.data_type;
          resolvedRule.r2_Value = (rule.column ? rule.column.type_desc == 'Date' : rule.warningColumn.r_value_type_desc == 'Date')
            ? setFormatDate(<Date>rule.value2, rule.column)
            : rule.value2 == null
            ? ''
            : rule.value2;
        }
        prepareRule.push(resolvedRule);
      });
      ruleSet.aggregates.map((rule, index) => {
        const resolvedRule: Segment.Request.Rule = {
          seq: index + 1,
          ruleName: rule.ruleName,
          ruleType: rule.ruleType,
          agg_Column_Alias: null,
          Exp_Comp_Op_Id: rule.operatorId as Segment.EXPCompOpId,
          exp_Comp_Label: null,
          exp_Comp_Label_Unit_Id: rule.unitId,
          l_TableId: applyWithConditionModel.payload.root_TableId,
          l_ColumnId: rule.column?.column_id,
          l_AggregateFuncId: rule.aggregateFuncId ? rule.aggregateFuncId : null,
          r_Value_Type: rule.column?.data_type,
          r_Value_Label: null,
          r_Value:
            (rule.column ? rule.column.type_desc == 'Date' : rule.warningColumn.r_value_type_desc == 'Date') &&
            rule.operatorId != 51 &&
            rule.operatorId != 52 &&
            rule.operatorId != 53 &&
            rule.operatorId != 151
              ? setFormatDate(<Date>rule.value1, rule.column)
              : rule.value1 == null
              ? ''
              : rule.value1,
          r2_Value_Type: null,
          r2_Value_Label: null,
          r2_Value: null,
        };
        if (rule.operatorId == 9 || rule.operatorId == 109) {
          resolvedRule.r2_Value_Type = rule.column.data_type;
          resolvedRule.r2_Value = (rule.column ? rule.column.type_desc == 'Date' : rule.warningColumn.r_value_type_desc == 'Date')
            ? setFormatDate(<Date>rule.value2, rule.column)
            : rule.value2 == null
            ? ''
            : rule.value2;
        }
        prepareAggregate.push(resolvedRule);
      });
      gatherRuleAndAggregateList[ruleSetIndex] = prepareRule.concat(prepareAggregate);
    });

    applyWithConditionModel.payload.RuleSets = condition.ruleSetList.map((ruleSet, index) => ({
      seq: index + 1,
      name: ruleSet.ruleName.isEmptyString() ? String(index + 1) : ruleSet.ruleName,
      rule_Op: ruleSet.selectSegment != null ? 0 : applyWithConditionModel.payload.set_Op,
      Filter_SegmentId: ruleSet.selectSegment,
      Filter_Flag_Exclude: ruleSet.flagExclude,
      rule_Logical_Equation: getRuleLogicalEquation(index),
      agg_Op: ruleSet.logicAggregateOperatorId as Segment.SegmentOperation,
      agg_Logical_Equation: getRuleAggregateLogicalEquation(index),
      Rules: gatherRuleAndAggregateList[index],
    }));

    const prepareGroupBylist: Segment.GroupBy[] = condition.groupByList.map((gb) => {
      return { columnid: gb.column_id, tableid: tableId.value };
    });
    applyWithConditionModel.payload.listGroupBy = prepareGroupBylist;
  };

  const onCloseModal = () => {
    condition.ruleSetList = [];
    condition.groupByList = [];
    filterSegmentList.value = [];
    columnList.value = [];
    aggregateColumnList.value = [];
    ruleSetOperator.value = null!;
    ruleAggregateOperator.value = null!;
    useGroupBy.value = false;
    key.value = 0;
    miniModal.value = false;
    modalType.value = null!;
    modalRuleSetIndex.value = null!;
    modalRuleSetName.value = null!;

    if (logicArea.value) {
      logicArea.value.innerHTML = '';
      logicArea.value = null!;
    }

    if (logicAggArea.value) {
      logicAggArea.value.innerHTML = '';
      logicAggArea.value = null!;
    }
    isAdvance.value = true;
    vm?.$emit('close-modal');
  };

  const onSubmit = () => {
    isSubmit.value = true;
    createPayload();
    apiService
      .apiRequest(applyWithConditionModel)
      .then((response) => {
        if (!props.isEdit) {
          router.push({
            path: '/segment/data-segment/preview',
            query: {
              ...router.currentRoute.value.query,
              segmentMode: 1,
              segmentId: response.data.segment_id,
              applySegmentId: response.data.apply_segment_id,
              applyDestSegmentId: null,
            },
          });
        } else {
          router.replace({
            path: '/segment/data-segment/preview',
            query: {
              ...router.currentRoute.value.query,
              segmentMode: 2,
              segmentOldId: paramurl.value,
              segmentId: response.data.segment_id,
              applySegmentId: response.data.apply_segment_id,
              applyDestSegmentId: response.data.apply_segment_id,
              countApply: (Math.random() + 1).toString(20).substring(5),
            },
          });
        }

        return onCloseModal();
      })
      .catch((err) => {
        openDefaultErrorModal(err);
      })
      .finally(() => (isSubmit.value = false));
  };

  const onDisabledAddRuleLimit = (lenght: number, config: Segment.Config | any, operator: any) => {
    if (operator) {
      if (config.multiple_ruleset || config.advance_mode) {
        if (lenght >= 5) return true;
        else return false;
      } else {
        if (lenght >= 1) return true;
      }
    } else return true;
  };

  onBeforeMount(() => {
    initColumns();
    if (props.isEdit) {
      return initRuleSet();
    }
  });

  watch(ruleAggregateOperator, () => {
    if (condition.ruleSetList && condition.ruleSetList.length) {
      nextTick(() => {
        condition.ruleSetList.forEach((ruleSet, ruleSetIndex) => {
          setLogicArea(ruleSetIndex);
          if (ruleSet.aggregates.length > 0) {
            return ruleSet.aggregates.forEach((rule, ruleIndex) => setAggregateRuleSetLogicArea(ruleSetIndex, ruleIndex, rule.ruleName));
          }
        });
      });
    }
  });

  watch([() => props.isOpen, () => props.isEdit, () => props.segment, () => props.columns], () => {
    if (props.isOpen) {
      if (props.segment?.set_op == 3 && props.isEdit) {
        const operator = setOperatorList.find((operator) => operator.value == 3);
        ruleSetOperator.value = operator!;
      } else {
        ruleSetOperator.value = null!;
      }
    }
    if (props.isOpen && props.columns) {
      useGroupBy.value = false;
      initColumns();
    }

    if (props.isOpen && props.isEdit && props.segment) {
      if (!props.segment) return;
      else if (props.segment) {
        const operator = setOperatorList.find((operator) => operator.value == props.segment?.set_op);
        if (operator?.value == 3) {
          isAdvance.value = true;
        } else {
          isAdvance.value = false;
          initRuleSet();
        }
      }
    }
  });

  return {
    filterSegmentList,
    condition,
    setOperatorList,
    setOperatorAggregateList,
    columnList,
    aggregateColumnList,
    groupbyColumnList,
    ruleSetOperator,
    ruleAggregateOperator,
    logicArea,
    logicAggArea,
    isSubmit,
    useGroupBy,
    miniModal,
    modalType,
    modalRuleSetIndex,
    modalRuleSetName,
    isAdvance,
    notiOperator,
    currentRuleSetOperator,
    addRuleSet,
    addFilterSegment,
    deleteRuleSet,
    deleteFilterSegment,
    deleteRule,
    addRule,
    addAggregate,
    addGroupBy,
    deleteAggregate,
    deleteGroupBy,
    onToggleAggregate,
    setAggregateOperator,
    onSelectRuleColumn,
    onSelectRuleColumnOperator,
    onSelectRuleColumnUnit,
    onSelectRuleAggregateColumn,
    onSelectRuleAggregateColumnOperator,
    onSelectRuleAggregateColumnFunction,
    onCloseModal,
    onSubmit,
    onInputColumnValue,
    onInputColumnValueDataList,
    onInputAggregateColumnValue,
    onSetRuleName,
    onSelectRuleGroupByFunction,
    onCloseMiniModal,
    onConfirmMiniModal,
    onSetDeleteRuleSetModal,
    onSetCancelMainModal,
    onFilterBySegment,
    setMainOperator,
    confirmSetMainOperator,
    unSetMainOperator,
    setConfigFlagSegment,
    onKeyRuleTag,
    onDisabledAddRuleLimit,
  };
}
