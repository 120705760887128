export const APPICATION_SYSTEM = {
  MICROSOFT: 1,
  GOOGLE: 2,
};

export const MFA_STATUS = {
  PENDING: 1,
  ACTIVE: 2,
};

export const STEP_PROGRESS = {
  START: 1,
  INSTALL: 2,
  SCAN_QR: 3,
  AUTHENTICATION: 4,
  COMPLETE: 5,
};
