//libs
import { ref, type Ref, onMounted, nextTick } from 'vue';

export interface Props {
  inputValue: { input: string };
  maxDigit: number;
  validError?: boolean;
}
export interface Emits {
  (e: 'clearErrorClass'): void;
  (e: 'callbackMaxDegit'): void;
}

export default function useTwoFactorInput(props: Props, emits: Emits) {
  const positionDigit: Ref<number> = ref(1);
  const inputDigit: Ref<{ [key: string]: string }> = ref({});

  function inputTwoFactor(e: any) {
    /* clear class error ทุกครั้งที่ input */
    emits('clearErrorClass');

    /* handle input (อ่านค่าได้เฉพาะตัวเลขเท่านั้น) */
    let input: string = e.target.value.replace(/\D/g, '');
    e.target.value = input;

    /* บันทึกค่า e.target.value ลงใน inputDigit.value และ set positionDigit */
    if (!e.target.value) {
      // 1.กรณียังไม่กรอก หรือ กรอกตัวแรกไม่ใช่ตัวเลข
      inputDigit.value[`input${positionDigit.value}`] = e.target.value.charAt(0);
      if (positionDigit.value != 1) {
        positionDigit.value = positionDigit.value - 1;
      }
    } else if (e.target.value.length >= props.maxDigit) {
      // 2.กรณีคัดลอกค่ามาใส่ (จำนวน input มากกว่าหรือเท่ากับ ค่าที่กำหนด)
      for (let i = 0; i < props.maxDigit; i++) {
        inputDigit.value[`input${positionDigit.value + i}`] = e.target.value.charAt(i);
      }
      positionDigit.value = props.maxDigit;
    } else if (e.target.value.length > 1) {
      // 3.กรณีกรอกทีละตัว (ตัวเลข) => e.target.value.length = 2
      inputDigit.value[`input${positionDigit.value}`] = e.target.value.charAt(0);
      inputDigit.value[`input${positionDigit.value + 1}`] = e.target.value.charAt(1);
      if (positionDigit.value != props.maxDigit) {
        positionDigit.value = positionDigit.value + 1;
      }
    } else {
      // 4.กรณีกรอกทีละตัว (ไม่ใช่ตัวเลข) => e.target.value จะอ่านค่ามาได้แค่ตัวเลข (e.target.value.length = 1)
      inputDigit.value[`input${positionDigit.value}`] = e.target.value.charAt(0);

      if (positionDigit.value == 1) {
        if (inputDigit.value['input6'] != undefined) {
          inputDigit.value['input6'] = '';
        }
      }
    }

    (document.getElementById(`digit-${positionDigit.value}`) as HTMLElement).focus();

    let arrValue: string[] = Object.values(inputDigit.value);
    let strInput: string = '';
    for (let i: number = 0; i < arrValue.length; i++) {
      if (i == props.maxDigit) {
        break;
      }
      strInput += arrValue[i];
    }
    props.inputValue.input = strInput;
    // callback if length has max degit
    if (strInput.length == props.maxDigit) {
      emits('callbackMaxDegit');
    }
  }

  function focusInput(e: any) {
    (document.getElementById(`digit-${positionDigit.value}`) as HTMLElement).focus();
  }

  function clearInput() {
    positionDigit.value = 1;
    setTimeout(() => {
      (document.getElementById(`digit-1`) as HTMLElement).focus();
    }, 150);
    inputDigit.value = {};
  }

  onMounted(async () => {
    await nextTick();
    clearInput();
  });

  return {
    positionDigit,
    inputDigit,
    inputTwoFactor,
    focusInput,
    clearInput,
  };
}
