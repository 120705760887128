import { ref, Ref, getCurrentInstance, nextTick } from 'vue';

import api from '@/services/api';

import templateModel from '@/models/communication/template';
import mkaModalConfig from '@/constants/components/mka/mka-modal';

import campaignModel from '@/models/communication/campaign';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';

import { RICH_MESSAGE_TEMPLATE } from '@/views/modules/communication/pages/line-oa-campaign/constants/richMessage';

// constants
import { NODE_ACTION_LINE_SUB_TYPE } from '@/constants/modules/mka/mkaDetail';
import { ContentTypeEnum } from '@/views/modules/communication/pages/line-oa-campaign/constants/contentType.enum';

interface Props {
  selectedChannel: Communication.Channel.Component.AllChannelTab | null;

  isOpen: boolean;
  nodeProps: MKA_Nodes.NodesProps;
}

interface LineContent {
  title: string;
  content: string;
  content_editor: string;
}
interface NodeAction {
  content: string;
  content_editor: string;
  title: string | null | undefined;
}
interface MixMessage {
  messageList: Communication.LineOA.Component.LineMessage[];
  richMessageList: Communication.LineOA.Component.RichMessage[];
  cardMessageList: Communication.LineOA.Component.CardMessge[];
}

export default function useActionLineModal(props: Props) {
  const { openErrorModal } = useValidationModal();

  const { fetchProviderListModel } = campaignModel();
  const { openDefaultErrorModal } = useValidationModal();

  const modalConfig = mkaModalConfig().nodeActionLine;
  const modalPage = ref(modalConfig.modalPage.SELECT_TEMPLATE);

  const vm = getCurrentInstance()?.proxy;
  const nodeData = ref<MKA_Nodes.NodesProps>(props.nodeProps);

  const { fetchTemplateDetailModel } = templateModel();

  const isSelectTemplateModalOpen: Ref<boolean> = ref(false);
  const isPreviewTemplateModalOpen: Ref<boolean> = ref(false);
  const selectedTemplate: Ref<Communication.Template.Template> = ref(null!);

  const keyChange: Ref<number> = ref(1);
  const isLoading: Ref<boolean> = ref(false);
  const providerList: Ref<Communication.Provider.Provider[]> = ref([]);
  const countDivMessagePanel: Ref<number> = ref(0); // hide or show in area preview
  const messageList: Ref<Communication.LineOA.Component.LineMessage[]> = ref([]);

  // previewMixMessageList : data for <preview-message>
  const previewMixMessageList: Ref<Communication.LineOA.Component.LineMessage[]> = ref([]);
  const indexPreviewMixMessage: Ref<number> = ref(0);

  // mixMessageList : data for message-panel
  const mixMessageList: Ref<MixMessage[]> = ref([
    {
      messageList: [],
      richMessageList: [],
      cardMessageList: [],
    },
    {
      messageList: [],
      richMessageList: [],
      cardMessageList: [],
    },
    {
      messageList: [],
      richMessageList: [],
      cardMessageList: [],
    },
  ]);
  //card message
  const cardMessageList: Ref<Communication.LineOA.Component.CardMessge[]> = ref([]);
  //rich message
  const richMessageList: Ref<Communication.LineOA.Component.RichMessage> = ref(null!);
  const selectedProvider: Ref<Campaign.LineOAProvider> = ref(null!);

  let requestCounter: any = 0;
  let selectMessageIndex: number | null = null;
  const messageData: any = ref({});

  const lineMessage: Ref<NodeAction[]> = ref([]);
  const template_id: Ref<number> = ref(0);

  const nodeActionLineStep_Var: any = ref([
    {
      active: true,
      label: 'Content',
    },
    {
      active: false,
      label: 'Sender',
    },
  ]);
  const nodeActionLine_Var: any = ref({
    mode: 2,
    templateId: [],
    providerId: null,
    contents: <LineContent[]>[],
  });

  const action_LineTemplateSelect_Connect: Ref<any> = ref({});

  const action_LineTemplateSelect_ConnectFunction = (connectFunction: any) => {
    action_LineTemplateSelect_Connect.value = connectFunction;
  };

  const onCloseTemplateModal = () => {
    isSelectTemplateModalOpen.value = false;
  };

  const onClickPreviewTemplate = (template: Communication.Template.Template) => {
    action_LineTemplateSelect_Connect.value.selectedTemplate = template;
    isPreviewTemplateModalOpen.value = true;
  };

  const onClosePreviewTemplateModal = () => {
    isPreviewTemplateModalOpen.value = false;
  };

  const autoSelectProvider = () => {
    if (nodeActionLine_Var.value.providerId != null) {
      const providerSelected: any = providerList.value.find((provider) => provider.providerid === nodeActionLine_Var.value.providerId);
      if (providerSelected != null) {
        selectedProvider.value = providerSelected;
        /* recheck again when component preview-message finished
        setAllMessageData(); */
      }
    }
  };
  function isJsonString(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  function buildObjectDisplayMessage(resolvedData: Communication.LineOA.Component.LineMessage) {
    const defaultLineMessage: Communication.LineOA.Component.LineMessage = {
      key: 0,
      sender: '',
      message: '',
      imageUrl: '',
      videoUrl: '',
      audioUrl: '',
      richMessage: undefined,
      cardMessageList: [],
      previewImageUrl: '',
      duration: 0,
      sref: '',
      thumbnail: {
        url: '',
        sref: '',
      },
    };
    const combinedObject = { ...defaultLineMessage, ...resolvedData };

    return combinedObject;
  }

  const resolveDisplayMessage = (contentList: Communication.LineOA.CreateCampaign.Content[], isMixType = false) => {
    const resolvedList: Communication.LineOA.Component.LineMessage[] = [];
    if (isMixType != true) {
      lineMessage.value = [];
    }

    if (contentList && contentList.length) {
      contentList.forEach((contentData) => {
        lineMessage.value.push({
          content: contentData.content,
          content_editor: contentData.content_editor,
          title: contentData.title,
        });

        const contentContentOBJ = JSON.parse(contentData.content);
        let displayContentMessage = '';
        if (isJsonString(contentData.content_editor) == false) {
          // normal text mobile preview
          displayContentMessage = contentData.content_editor;
        } else {
          // Personalize mobile preview
          const contentEditorOBJ = JSON.parse(contentData.content_editor);
          displayContentMessage = contentEditorOBJ.text ? decodeURI(contentEditorOBJ.text as string) : '';
        }

        let displayContent: Communication.LineOA.Component.LineMessage = { key: keyChange.value };
        if (contentContentOBJ.type == 'text') {
          displayContent = {
            key: keyChange.value,
            sender: selectedProvider.value?.name || '',
            message: displayContentMessage,
          };
        } else if (contentContentOBJ.type == 'image') {
          displayContent = {
            key: keyChange.value,
            sender: selectedProvider.value?.name || '',
            imageUrl: contentContentOBJ.previewImageUrl,
            previewImageUrl: contentContentOBJ.previewImageUrl,
            sref: contentContentOBJ.sref,
          };
        } else if (contentContentOBJ.type == 'video') {
          displayContent = {
            key: keyChange.value,
            sender: selectedProvider.value?.name || '',
            videoUrl: contentContentOBJ.originalContentUrl,
            previewImageUrl: contentContentOBJ.previewImageUrl,
            sref: contentContentOBJ.sref,
          };
        } else if (contentContentOBJ.type == 'audio') {
          displayContent = {
            key: keyChange.value,
            sender: selectedProvider.value?.name || '',
            audioUrl: contentContentOBJ.originalContentUrl,
            duration: contentContentOBJ.duration,
            sref: contentContentOBJ.sref,
          };
        } else {
          displayContent = { key: keyChange.value, sender: selectedProvider.value?.name || '' };
        }
        resolvedList.push(displayContent);
        keyChange.value++;
      });
    } else {
      resolvedList.push({ key: keyChange.value, sender: selectedProvider.value?.name || '', message: '' });
      keyChange.value++;
    }

    if (isMixType != true) {
      // case user select Text Message
      for (let i = 0; i < resolvedList.length; i++) {
        mixMessageList.value[i].messageList.push(resolvedList[i]);
        const objData = buildObjectDisplayMessage(resolvedList[i]);
        previewMixMessageList.value.push(objData);
      }
      // render div from length resolvedList
      countDivMessagePanel.value = resolvedList.length;
    } else {
      // case user select Mix Message
      mixMessageList.value[indexPreviewMixMessage.value].messageList.push(resolvedList[0]);

      const objData = buildObjectDisplayMessage(resolvedList[0]);
      previewMixMessageList.value.push(objData);
    }

    if (isMixType != true) {
      return (messageList.value = resolvedList);
    } else {
      return resolvedList;
    }
  };

  function resolveDisplayCard(content: Communication.LineOA.CreateCampaign.Content[], isMixType = false) {
    let resolvedList: Communication.LineOA.Component.CardMessge[] = [];
    let url = '';
    let sref = '';

    if (content && content.length) {
      content.forEach((content) => {
        let contentEditor: any;
        if (isJsonString(content.content_editor) && content.content_editor) {
          contentEditor = JSON.parse(content.content_editor || '');
        } else {
          contentEditor = content.content_editor || '';
        }
        if (content.content_editor !== '{}') {
          contentEditor.list_card.forEach((card: Communication.LineOA.Component.CardMessge) => {
            resolvedList.push({
              ...card,
              sender: selectedProvider.value?.name ? selectedProvider.value.name : '',
            });
          });

          url = contentEditor.thumbnail?.url;
          sref = contentEditor.thumbnail?.sref;
        } else {
          resolvedList = [];
        }

        keyChange.value++;
      });
    } else {
      keyChange.value++;
      return resolvedList;
    }
    lineMessage.value = content as NodeAction[]; // save content to DB

    if (isMixType != true) {
      // case user select Card Message fix 1 div
      mixMessageList.value[0].cardMessageList = resolvedList;
      countDivMessagePanel.value = 1;
    } else {
      // case user select Mix Message
      mixMessageList.value[indexPreviewMixMessage.value].cardMessageList = resolvedList;
    }

    // add data for send <preview-message>
    const defaultLineMessage: Communication.LineOA.Component.LineMessage = {
      key: keyChange.value - 1,
      sender: selectedProvider.value?.name ? selectedProvider.value.name : '',
      message: undefined,
      imageUrl: '',
      videoUrl: '',
      audioUrl: '',
      richMessage: undefined,
      cardMessageList: resolvedList,
      previewImageUrl: '',
      duration: 0,
      sref: '',
      thumbnail: {
        url: url,
        sref: sref,
      },
    };

    // build data to <preview-message>
    if (isMixType != true) {
      previewMixMessageList.value[0] = defaultLineMessage;
    } else {
      previewMixMessageList.value[indexPreviewMixMessage.value] = defaultLineMessage;
    }

    return (cardMessageList.value = resolvedList);
  }

  function resolveDisplayRichMessage(content: Communication.LineOA.CreateCampaign.Content[], isMixType = false) {
    let resolvedContent: Communication.LineOA.Component.RichMessage = null!;

    if (content && content.length) {
      content.forEach((content) => {
        let contentEditor: any;
        if (isJsonString(content.content_editor) && content.content_editor) {
          contentEditor = JSON.parse(content.content_editor || '');
        } else {
          contentEditor = content.content_editor || '';
        }
        if (content.content_editor !== '{}') {
          resolvedContent = {
            ...contentEditor,
            gridType: RICH_MESSAGE_TEMPLATE.find((message) => message.key == contentEditor.gridType),
            sender: selectedProvider.value?.name || '',
          };
        } else {
          resolvedContent = null!;
        }
        keyChange.value++;
      });
    } else {
      keyChange.value++;
      return resolvedContent;
    }
    lineMessage.value = content as NodeAction[]; // save content to DB

    if (isMixType != true) {
      countDivMessagePanel.value = 1;
      // case user select Rich Message fix 1 div
      mixMessageList.value[0].richMessageList.push(resolvedContent);
    } else {
      // case user select Mix Message
      mixMessageList.value[indexPreviewMixMessage.value].richMessageList.push(resolvedContent);
    }

    // add data for send <preview-message>
    const defaultLineMessage: Communication.LineOA.Component.LineMessage = {
      key: keyChange.value - 1,
      sender: selectedProvider.value?.name ? selectedProvider.value.name : '',
      message: undefined,
      imageUrl: '',
      videoUrl: '',
      audioUrl: '',
      richMessage: resolvedContent,
      cardMessageList: [],
      previewImageUrl: '',
      duration: 0,
      sref: '',
      thumbnail: {
        url: '',
        sref: '',
      },
    };

    // build data to <preview-message>
    if (isMixType != true) {
      previewMixMessageList.value[0] = defaultLineMessage;
    } else {
      previewMixMessageList.value[indexPreviewMixMessage.value] = defaultLineMessage;
    }

    return (richMessageList.value = resolvedContent);
  }

  function resolveDisplayMultiMessage(content: Communication.LineOA.CreateCampaign.Content[]) {
    const resolvedContent: Communication.LineOA.Component.RichMessage = null!;
    let dataList: Communication.LineOA.CreateCampaign.Content[] = [];
    countDivMessagePanel.value = content.length >= 1 ? content.length : 0;
    if (content && content.length) {
      content.forEach((contentText, index) => {
        let contentEditor: any;
        dataList = [];
        dataList.push(contentText);
        if (isJsonString(contentText.content_editor) && contentText.content_editor) {
          contentEditor = JSON.parse(contentText.content_editor || '');
        } else {
          contentEditor = contentText.content_editor || '';
        }

        if (contentEditor.type == ContentTypeEnum.TEXT) {
          mixMessageList.value[index].messageList = resolveDisplayMessage(dataList, true);
        } else if (contentEditor.type == ContentTypeEnum.IMAGE) {
          mixMessageList.value[index].messageList = resolveDisplayMessage(dataList, true);
        } else if (contentEditor.type == ContentTypeEnum.VIDEO) {
          mixMessageList.value[index].messageList = resolveDisplayMessage(dataList, true);
        } else if (contentEditor.type == ContentTypeEnum.AUDIO) {
          mixMessageList.value[index].messageList = resolveDisplayMessage(dataList, true);
        } else if (contentEditor.type == ContentTypeEnum.RICH_MESSAGE) {
          mixMessageList.value[index].richMessageList.push(resolveDisplayRichMessage(dataList, true));
        } else if (contentEditor.type == ContentTypeEnum.CARD_MESSAGE) {
          mixMessageList.value[index].cardMessageList = resolveDisplayCard(dataList, true);
        }

        indexPreviewMixMessage.value++;
      });
    } else {
      keyChange.value++;
      return resolvedContent;
    }
    addSpanMkaAreaPreview();
    lineMessage.value = content as NodeAction[];
  }
  const setAllMessageData = () => {
    const allMessagePreviewData: any = [];
    nodeActionLine_Var.value.templateId.map((templateId: any) => {
      const message: any = messageData.value[templateId];
      template_id.value = templateId;

      if (message != null) {
        for (let i = 0; i < message.contents.length; i++) {
          allMessagePreviewData.push(message.contents[i]);
        }
      }
    });

    if (messageData.value[template_id.value]) {
      if (messageData.value[template_id.value].subtype == NODE_ACTION_LINE_SUB_TYPE.MESSAGE) {
        resolveDisplayMessage(allMessagePreviewData as Communication.LineOA.CreateCampaign.Content[]);
      } else if (messageData.value[template_id.value].subtype == NODE_ACTION_LINE_SUB_TYPE.RICH_MESSAGE) {
        resolveDisplayRichMessage(allMessagePreviewData as Communication.LineOA.CreateCampaign.Content[]);
      } else if (messageData.value[template_id.value].subtype == NODE_ACTION_LINE_SUB_TYPE.CARD_MESSAGE) {
        resolveDisplayCard(allMessagePreviewData as Communication.LineOA.CreateCampaign.Content[]);
      } else if (messageData.value[template_id.value].subtype == NODE_ACTION_LINE_SUB_TYPE.MULTI_MESSAGE) {
        resolveDisplayMultiMessage(allMessagePreviewData as Communication.LineOA.CreateCampaign.Content[]);
      }
    }
  };

  const fetchTemplateDetail = async (templateId: number, isPreviewMode = false): Promise<void> => {
    // เคลียข้อมูลเพื่อแสดง ui ข้อมูลอันใหม่เลย
    keyChange.value = 1;
    indexPreviewMixMessage.value = 0;
    previewMixMessageList.value = [];
    messageList.value = [];
    cardMessageList.value = [];
    richMessageList.value = null!;
    mixMessageList.value = [
      {
        messageList: [],
        richMessageList: [],
        cardMessageList: [],
      },
      {
        messageList: [],
        richMessageList: [],
        cardMessageList: [],
      },
      {
        messageList: [],
        richMessageList: [],
        cardMessageList: [],
      },
    ];
    fetchTemplateDetailModel.payload.TemplateId = templateId;
    isLoading.value = true;
    requestCounter += 1;
    return api
      .apiRequest(fetchTemplateDetailModel)
      .then((response) => {
        const responseData = response.data as Communication.Template.Template;
        messageData.value[responseData.template_id] = responseData;
        if (isPreviewMode) {
          messageData.value[responseData.template_id].name = props.nodeProps.info as string;
          messageData.value[responseData.template_id].contents = props.nodeProps.payload.contents;
        }
        Promise.resolve();
      })
      .catch((err) => {
        openDefaultErrorModal(err);
        Promise.reject();
      })
      .finally(() => {
        requestCounter -= 1;
        if (requestCounter <= 0) {
          isLoading.value = false;
          setAllMessageData();
        }
      });
  };

  const fetchAllTemplate = async (isPreviewMode = false) => {
    messageData.value = {};
    for (const templateId of nodeActionLine_Var.value.templateId) {
      await fetchTemplateDetail(Number(templateId), isPreviewMode);
    }
  };

  const fetchProviderList = async (): Promise<void> => {
    isLoading.value = true;
    fetchProviderListModel.payload.Channel = 3;
    fetchProviderListModel.payload.Page = 1;
    fetchProviderListModel.payload.Limit = 100;

    return api
      .apiRequest(fetchProviderListModel)
      .then((response) => {
        providerList.value = response.data.records as Communication.Provider.Provider[];
        autoSelectProvider();
        Promise.resolve();
      })
      .catch((err) => {
        openDefaultErrorModal(err);
        Promise.reject();
      })
      .finally(() => {
        isLoading.value = false;
      });
  };

  const nextStep = () => {
    if (!nodeActionLine_Var.value.templateId.length) {
      nodeActionLine_Var.value.contents = [];
    }
    modalPage.value = modalConfig.modalPage.SELECT_PROVIDER;
    const nodeActionLineStep_VarTemp = nodeActionLineStep_Var.value;
    nodeActionLineStep_VarTemp[1].active = true;
    nodeActionLineStep_Var.value = nodeActionLineStep_VarTemp;

    onClosePreviewTemplateModal();

    if (action_LineTemplateSelect_Connect.value.selectedTemplate != null) {
      const nodeActionLine_Var_Temp = nodeActionLine_Var.value;

      if (selectMessageIndex != null) {
        nodeActionLine_Var_Temp.templateId[selectMessageIndex] = action_LineTemplateSelect_Connect.value.selectedTemplate.template_id;

        // set sms node content

        nodeActionLine_Var_Temp.contents[selectMessageIndex] = {
          title: action_LineTemplateSelect_Connect.value.selectedTemplate.contents[0].title ?? '',
          content: action_LineTemplateSelect_Connect.value.selectedTemplate.contents[0].content ?? '',
          content_editor: action_LineTemplateSelect_Connect.value.selectedTemplate.contents[0].content_editor ?? '',
        };
      } else {
        nodeActionLine_Var_Temp.templateId.push(action_LineTemplateSelect_Connect.value.selectedTemplate.template_id);

        // set sms node content

        nodeActionLine_Var_Temp.contents.push({
          title: action_LineTemplateSelect_Connect.value.selectedTemplate.contents[0].title ?? '',
          content: action_LineTemplateSelect_Connect.value.selectedTemplate.contents[0].content ?? '',
          content_editor: action_LineTemplateSelect_Connect.value.selectedTemplate.contents[0].content_editor ?? '',
        });
      }
      action_LineTemplateSelect_Connect.value.selectedTemplate = null;
      nodeActionLine_Var.value = nodeActionLine_Var_Temp;
      fetchAllTemplate();
    } else {
      fetchAllTemplate(true);
    }

    selectMessageIndex = null;
  };

  const previewMode = async () => {
    modalPage.value = modalConfig.modalPage.PREVIEW;
    const nodeActionLineStep_VarTemp = nodeActionLineStep_Var.value;
    nodeActionLineStep_VarTemp[1].active = true;
    nodeActionLineStep_Var.value = nodeActionLineStep_VarTemp;

    await fetchAllTemplate(true);
    selectMessageIndex = null;
  };

  const onChangeTemplate = () => {
    lineMessage.value = [];
    selectMessageIndex = 0;

    modalPage.value = modalConfig.modalPage.SELECT_TEMPLATE;

    const nodeActionLineStep_VarTemp = nodeActionLineStep_Var.value;
    nodeActionLineStep_VarTemp[1].active = false;
    nodeActionLineStep_Var.value = nodeActionLineStep_VarTemp;
  };

  const onRemoveTemplate = (index: number) => {
    const nodeActionLine_Var_Temp = nodeActionLine_Var.value;
    const templateIdTarget: number = nodeActionLine_Var_Temp.templateId[index];
    nodeActionLine_Var_Temp.templateId.splice(index, 1);
    nodeActionLine_Var_Temp.contents.splice(index, 1);
    nodeActionLine_Var.value = nodeActionLine_Var_Temp;
    const messageDataTemp: any = messageData.value;
    delete messageDataTemp[templateIdTarget];
    messageData.value = messageDataTemp;

    if (nodeActionLine_Var_Temp.templateId.length == 0) {
      modalPage.value = modalConfig.modalPage.SELECT_TEMPLATE;
      selectedProvider.value = null!;
      selectMessageIndex = null;

      const nodeActionLineStep_VarTemp = nodeActionLineStep_Var.value;
      nodeActionLineStep_VarTemp[1].active = false;
      nodeActionLineStep_Var.value = nodeActionLineStep_VarTemp;
    }
  };

  const nodeActionLineValidate = () => {
    const nodeActionLine_Var_Temp = nodeActionLine_Var.value;
    if (nodeActionLine_Var_Temp.mode === 1) {
    } else if (nodeActionLine_Var_Temp.mode === 2) {
      return (
        nodeActionLine_Var_Temp.templateId.length > 0 && nodeActionLine_Var_Temp.providerId != null && nodeActionLine_Var_Temp.contents.length > 0
      );
    } else if (nodeActionLine_Var_Temp.mode === 3) {
    }
    return true;
  };

  const nodeActionLineLoad = async () => {
    modalPage.value = modalConfig.modalPage.SELECT_TEMPLATE;
    selectedProvider.value = null!;
    selectMessageIndex = null;

    setAllMessageData();

    const nodeActionLineStep_VarTemp = nodeActionLineStep_Var.value;
    nodeActionLineStep_VarTemp[1].active = false;
    nodeActionLineStep_Var.value = nodeActionLineStep_VarTemp;

    nodeActionLine_Var.value = nodeData.value.payload != null ? JSON.parse(JSON.stringify(nodeData.value.payload)) : {};

    if (nodeActionLine_Var.value.mode == 2 && nodeActionLine_Var.value.templateId.length > 0) {
      await previewMode();
      await fetchProviderList();
    } else {
      await fetchProviderList();
    }
  };
  const nodeActionLineSave = () => {
    if (nodeActionLineValidate() == true) {
      nodeData.value.payload = nodeActionLine_Var.value || {};
      nodeData.value.payload.contents = lineMessage.value || {};
      nodeData.value.info = messageData.value[nodeActionLine_Var.value.templateId].name;
      return true;
    } else {
      openErrorModal('ข้อมูลไม่ถูกต้อง', 'กรุณาตรวจสอบข้อมูล');
      return null;
    }
  };

  const onSelectProvider = (provider: Communication.Provider.Provider) => {
    selectedProvider.value = provider;
    nodeActionLine_Var.value.providerId = provider.providerid;
    vm?.$emit('select-sender', JSON.parse(JSON.stringify(selectedProvider.value)));

    // comment because not render ui again when select provider
    /* setAllMessageData(); */
  };

  const onAddTemplate = () => {
    modalPage.value = modalConfig.modalPage.SELECT_TEMPLATE;
    selectedProvider.value = null!;
    selectMessageIndex = null;
  };

  const onChangeAndEdit = () => {
    nextStep();
  };

  function onClickApply() {
    const finalValid = nodeActionLineSave();
    if (finalValid) {
      vm?.$emit('apply-modal', nodeData.value);
    }
  }

  function onClickClose() {
    vm?.$emit('close-modal');
  }

  function onClickCancel() {
    vm?.$emit('cancel-modal');
  }
  async function addSpanMkaAreaPreview() {
    await nextTick();

    for (let i = 0; i < lineMessage.value.length; i++) {
      const nodeId: string = 'lineText-' + i;
      const parentEle = document.getElementById(nodeId)!;
      if (parentEle != null) {
        if (isJsonString(lineMessage.value[i].content_editor) == false) {
          // normal text
          parentEle.innerHTML = lineMessage.value[i].content_editor;
        } else {
          // Personalize
          const parseMessage = JSON.parse(lineMessage.value[i].content_editor);
          if (parseMessage.type == 'text') {
            const decodeLineNode = decodeURI((parseMessage.text as string) || '');
            parentEle.innerHTML = decodeLineNode;
          } else if (parseMessage.type == 'image') {
            const imgUrl: string = parseMessage.originalContentUrl;
            parentEle.innerHTML = `<img src="${imgUrl}">`;
          } else if (parseMessage.type == 'video') {
            const videoUrl: string = parseMessage.originalContentUrl;
            parentEle.innerHTML = `<video src="${videoUrl}" controls></video>`;
          } else if (parseMessage.type == 'audio') {
            const audioUrl: string = parseMessage.originalContentUrl;
            parentEle.innerHTML = `<audio src="${audioUrl}" controls></audio>`;
          }
        }
      }
    }
  }

  // function setPreviewMessageSize() {
  //   nextTick(() => {
  //     const previewCardContainer = document?.querySelectorAll('.preview-card-message') as any;
  //     if (previewCardContainer.length) {
  //       for (let i = 0; i < previewCardContainer.length; i++) {
  //         previewCardContainer[i].style.height = `${previewCardContainer[i].offsetHeight * 0.50}px`;
  //       }
  //     }
  //   });
  // }

  return {
    action_LineTemplateSelect_ConnectFunction,
    onCloseTemplateModal,
    onClickPreviewTemplate,
    onClosePreviewTemplateModal,
    autoSelectProvider,
    resolveDisplayMessage,
    fetchTemplateDetail,
    fetchAllTemplate,
    fetchProviderList,
    nextStep,
    previewMode,
    onChangeTemplate,
    onRemoveTemplate,
    nodeActionLineValidate,
    nodeActionLineLoad,
    nodeActionLineSave,
    addSpanMkaAreaPreview,
    onSelectProvider,
    onAddTemplate,
    onChangeAndEdit,
    onClickApply,
    onClickClose,
    onClickCancel,

    //variable
    modalPage,
    modalConfig,
    nodeActionLineStep_Var,
    selectedProvider,
    providerList,
    nodeActionLine_Var,
    template_id,
    messageList,
    messageData,
    cardMessageList,
    richMessageList,
    mixMessageList,
    countDivMessagePanel,
    previewMixMessageList,
    action_LineTemplateSelect_Connect,
    isPreviewTemplateModalOpen,
  };
}
