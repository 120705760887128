export default {
  forget_password: {
    title: 'Forgot Password',
    description_first: 'Enter your email below and we’ll email you with',
    description_second: 'instructions on how to reset your password.',
    placeholder: 'Your Email',
    button: 'Send',
    error_text: 'Email is invalid',
    notemail_text: 'ไม่พบอีเมลนี้ในระบบ กรุณาตรวจสอบอีกครั้ง',
    back: 'กลับ',

    check_email: {
      title: 'Help is on the way',
      description_first: 'We’re combing through our records to find the CHOCO CDP account',
      description_second: 'For ',
      go_back: 'Go back to ChocoFor ',
      link_login: 'portal.chococdp.com/login',
    },

    expire_email: {
      title: 'Your URL link has expire',
      description_first: 'If you want to continue reset password,',
      re_click: 'please re’ click this link ',
      link_forgot_password: 'portal.chococdp.com/forgot-password',
    },
  },

  reset_password: {
    title: 'Create New Password',
    sub_title_step1: 'Your new password must be different',
    sub_title_step2: 'from previous used passwords.',
    placeholder_password: 'New Password',
    placeholder_confirm_password: 'Confirm New Password',
    topic_rules: 'Protect your account, make sure your password',
    rule1: 'Password must match',
    rule2: '8 Characters',
    rule3: 'One lowercase characters',
    rule4: 'One uppercase characters',
    rule5: 'One special characters',
    btn: 'Reset Pasword',
  },
  // ---------------------- MFA ----------------------------
  text_header: 'ChocoCDP การยืนยันตัวตน',
  description_header1: 'ChocoCDP เพิ่มความปลอดภัยบัญชีของคุณ',
  description_header2: 'ด้วยขั้นตอนยืนยันตัวตน 2 ขั้น (Two-Factor Authentication)',
  step: 'ขั้นตอนที่',
  // --------- step 1 ----------
  start_by_getting_the_app: 'ดาวน์โหลดแอปพลิเคชัน',
  please_install1: 'กรุณาติดตั้งแอปพลิเคชัน Google Authenticator หรือ Microsoft Authenticator ลงบนโทรศัพท์มือถือของท่าน',
  divider: 'หรือ',
  // --------- step 2 ----------
  install_an_authentication_app: 'ติดตั้งแอปพลิเคชัน Authentication',
  please_install2: 'กรุณาติดตั้งแอปพลิเคชัน ',
  on_your_mobile: ' ลงบนโทรศัพท์มือถือของท่าน',
  // --------- step 3 ----------
  scan_qr_code: 'สแกน QR Code',
  use_the: 'ใช้ แอปพลิเคชัน',
  application_to_scan: 'หลังจากสแกน QR Code',
  application_with_your_account: '  จะเชื่อมต่อกับบัญชีของคุณ ',
  scan_to_next: 'หลังสแกน QR code กรุณากดปุ่ม "ถัดไป"',
  // --------- step 4 ----------
  verify_authentication_code: 'กรอกรหัส Authentication',
  enter_the_six_digit: 'กรอกรหัส 6 หลักของแอปพลิเคชัน Authentication',
  open_your_two_factor: 'เปิดแอปสำหรับการยืนยันตัวตนแบบสองขั้นตอน (TOTP) ของคุณเพื่อดูรหัส 6 หลักในการยืนยันตัวตน',
  error: 'ขออภัยรหัส Authentication ของคุณไม่ถูกต้อง กรุณาลองอีกครั้งหรือหากพบปัญหากรุณาติดต่อเจ้าหน้าที่ ChocoCRM',
  button_complete: 'ยืนยันตัวตน',
  loading: 'กำลังโหลด',
  // --------- step complete ----------
  authenticator_setup_complete: 'Authenticator setup complete!',
  description_header_complete: 'You can now generate verification codes for your ChocoCDP accounts',
  done: 'Done',
  // --------- button ----------
  back: 'ย้อนกลับ',
  next: 'ถัดไป',
  trouble_text: 'พบปัญหา? ติดต่อเจ้าหน้าที่ ChocoCRM',
};
