export default function couponManagementModel() {
  const fetchDropDownStatusList: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/CouponSet/GetStatus`,
    method: 'GET',
    payload: {},
  };
  const fetchDropDownSourceList: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/CouponSet/GetSource`,
    method: 'GET',
    payload: {},
  };
  const fetchDropDownFormatList: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/CouponSet/GetFormat`,
    method: 'GET',
    payload: {},
  };
  const fetchDropCouponTypeList: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/CouponSet/GetCouponType`,
    method: 'GET',
    payload: {},
  };
  const detailChart: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/CouponSet/GetCouponPieData`,
    method: 'POST',
    payload: {
      CouponSetId: null,
    },
  };
  const fetchModuleChannelList: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/CouponCode/GetModuleChannel`,
    method: 'GET',
    payload: {},
  };
  const fetchStatusList: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/CouponCode/GetFillter`,
    method: 'GET',
    payload: {},
  };

  const fetchCouponBatchList: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/CouponCode/GetBatch`,
    method: 'POST',
    payload: {
      CouponSetId: null,
    },
  };
  const fetchCouponSetList: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/CouponSet/ListCouponSet`,
    method: 'POST',
    payload: {
      // search: '', //กรณีมีการ search
      // Filters: [], //กรณีต้องการให้แสดงเฉพาะ statusนั้นๆ
      // Ordering: [], //กรณีต้องการให้เรียงตาม column นั้นๆ โดยเรียงจาก  asc หรือ desc
      // Page: 1,
      // Limit: 10000,
    },
  };

  const createCouponSet: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/CouponSet/Create`,
    method: 'POST',
    payload: {
      name: null,
      detail: null,
      status: null,
      image_file_sref: null,
      value_reference: null,
      credit: null,
      reserved: null,
      issue: null,
      remaining: null,
    },
  };

  const editCouponSet: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/CouponSet/Edit`,
    method: 'POST',
    payload: {},
  };

  const deleteCouponSet: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/CouponSet/Delete`,
    method: 'POST',
    payload: {
      coupon_ref: null,
    },
  };

  const cancelCouponSet: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/CouponSet/Cancel`,
    method: 'POST',
    payload: {
      coupon_id: null,
    },
  };

  const detailCouponSet: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/CouponSet/DetailCouponSet`,
    method: 'POST',
    payload: {
      coupon_id: null,
    },
  };

  // download template
  const downloadTemplateCouponModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/CouponCode/GetTemplate`,
    method: 'GET',
    payload: {},
  };

  const exportFileCouponSet: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/CouponCode/Export`,
    method: 'GET',
    payload: {},
    responseType: 'blob',
  };

  const fetchCouponCodelist: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/CouponCode/ListCouponCode`,
    method: 'POST',
    payload: {},
  };

  const uploadCouponCodeModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/CouponCode/Upload`,
    method: 'POST',
    payload: {
      File: null,
      Records_Preview: null,
    },
  };

  const validateCouponCode: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/CouponCode/Validate`,
    method: 'POST',
    payload: {
      CouponId: null,
      Condition: null,
    },
  };

  const confirmCouponCodeModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/CouponCode/Confirm`,
    method: 'POST',
    payload: {
      CouponId: null,
      Image_Name: null,
      Image: null,
      Name: null,
      Description: null,
      Value_Reference: null,
      Status: null,
    },
  };

  return {
    fetchCouponSetList,
    createCouponSet,
    editCouponSet,
    deleteCouponSet,
    cancelCouponSet,
    detailCouponSet,
    downloadTemplateCouponModel,
    exportFileCouponSet,
    fetchCouponCodelist,
    uploadCouponCodeModel,
    validateCouponCode,
    confirmCouponCodeModel,
    fetchCouponBatchList,
    fetchStatusList,
    fetchModuleChannelList,
    detailChart,
    fetchDropDownStatusList,
    fetchDropDownSourceList,
    fetchDropDownFormatList,
    fetchDropCouponTypeList,
  };
}
